import { ReactComponent as CubeSolidIcon } from "../../../assets/faIcons/cube-solid.svg";
import { ReactComponent as CubesSolidIcon } from "../../../assets/faIcons/cubes-solid.svg";
import { ReactComponent as MapLocationDotSolidIcon } from "../../../assets/faIcons/map-location-dot-solid.svg";
import { ReactComponent as MicrochipSolidIcon } from "../../../assets/faIcons/microchip-solid.svg";
import { ReactComponent as SitemapSolidIcon } from "../../../assets/faIcons/sitemap-solid.svg";
import { withRouter } from "react-router-dom";
import { userSelectors } from "../../../globalStore/slices/user/userSlice";
import { useSelector } from "react-redux";
import Drawer from "./Drawer/FixedDrawer";
import InventoryIcon from '@mui/icons-material/Inventory';
import NearMeIcon from '@mui/icons-material/NearMe';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

function SideNav(props) {
  const userRoles = useSelector(userSelectors.userActionsSelector);
  const userAvailableViews = useSelector(userSelectors.userAvailableViewsSelector);

  const linksMap = {};
  // This is where we determine what goes into the side nav. This is super clunky and NOT scalable.
  // Been trying to push for time to be smart about this, but until then we are forced to hard code this in.
  Object.keys(userRoles).forEach((action) => {
    switch (action) {
      case "View Asset History":
        linksMap.assetHistory = true;
        linksMap.assets = true;
        linksMap.assetStatus = true;
        break;
      case "View Batches":
        linksMap.batches = true;
        break;
      case "View Devices":
        linksMap.devices = true;
        break;
      case "View Facilities":
        linksMap.facilities = true;
        linksMap.locations = true;
        break;
      case "View Inventory":
        linksMap.inventory = true
        linksMap.inventoryStatus = true;
        linksMap.inventoryHistory = true
        break;
      case "View Organizations":
        linksMap.organizations = true;
        break;
      case "View Products":
        linksMap.products = true;
        break;
      case "View Settings":
        linksMap.settings = true;
        break;
      case "View Users":
        linksMap.users = true;
        break;
      default:
        linksMap.home = true;
        linksMap.support = true;
        linksMap.information = true;
        linksMap.automatedProcesses = true;
        break;
    }
  });

  const navLinks = [
    {
      acceptedViews: ["View Assets"],//TODO: part of cleanup //need at least one. Question is should we have in config all 3 options to choose from or just one. because what if here, we say they need at least one, but in the config, we only have one option. Then it's not really a choice. So, we should have all 3 options in the config. ... or just one in the config and one here. But then, what if we want to add more options in the future? We would have to update the config and the code. So, it's better to have all options in the config.
      icon: <MapLocationDotSolidIcon />,
      label: "Assets",
      name: "assets",
    },
    {
      acceptedViews: ["View Products"],
      icon: <CubeSolidIcon />,
      label: "Products",
      name: "products",
    },
    {
      acceptedViews: ["View Batches"],
      icon: <CubesSolidIcon />,
      label: "Batches",
      name: "batches",
    },
    {
      acceptedViews: ["View Facilities"],
      icon: <NearMeIcon />,
      label: "Facilities",
      name: "facilities",
    },
    {
      acceptedViews: ["View Devices"],
      icon: <MicrochipSolidIcon />,
      label: "Trackers",
      name: "devices",
    },
    {
      acceptedViews: ["View Inventory"],
      icon: <InventoryIcon />,
      label: "Inventory",
      name: "inventory",
    },
    {
      acceptedViews: ["View Organizations"],
      icon: <SitemapSolidIcon />,
      label: "Organizations",
      name: "organizations",
    },
    {
      acceptedViews: ["View Users"],
      icon: <PersonIcon />,
      label: "Users",
      name: "users",
    },
    // { name: "automatedProcesses", label: "Automated Processes", icon: <GearsSolidIcon/> },
    {
      acceptedViews: ["View Settings"],
      icon: <SettingsIcon />,
      label: "Settings",
      name: "settings",
    },
  ]
  //use the acceptedViews to filter out the navLinks that the user does not have access to based on userAvailableViews
  .filter((link) => userAvailableViews.some((view) => link.acceptedViews.includes(view)))


  const stickyNavLinks = []

  return (
    <Drawer navLinks={navLinks} stickyNavLinks={stickyNavLinks} {...props} />
  );
}
export default withRouter(SideNav);
