import moment from "moment-timezone";

// function for rendering time from the backend (UTC) in standard 12 hour clock, e.g., 2020-08-23 12:32 p.m, in the users' set timezone.
export const utc_to_local_12_hour_clock = (
  timeOfLog,
  timeZone
) => {

  return moment
    .utc(timeOfLog || null, "YYYYMMDDHHmmss")
    .tz(timeZone)
    .format("YYYY-MM-DD hh:mm:ss a");
};

// function for rendering time from the backend (UTC) in 24 hour clock, e.g., 2020-08-23 14:32, in the users' set timezone. Use this function instead of the civil time above for transforming timeOfLog strings in the table column data accessors
export const utc_to_local_24_hour_clock = (
  timeOfLog,
  timeZone
) => {

  return moment
    .utc(timeOfLog, "YYYYMMDDHHmmss")
    .tz(timeZone)
    .format("YYYY-MM-DD HH:mm:ss");
};

// asset table uses unix time for timeOfLog, asset history uses world clock. For accessors and data sorting, just use row.timeOfLog and then render in the table cell using one of the functions below:

export const unix_utc_to_local_12_hour_clock = (
  timeOfLog,
  timeZone
) => {

  return moment
    .utc(timeOfLog || null, "x")
    .tz(timeZone)
    .format("YYYY-MM-DD hh:mm:ss a");
};

export const unix_utc_to_local_24_hour_clock = (
  timeOfLog,
  timeZone
) => {

  return moment
    .utc(timeOfLog || null, "x")
    .tz(timeZone)
    .format("YYYY-MM-DD hh:MM:ss");
};

export const date_interval_util = (selection, timeZone) => {
  const currentTime = moment().tz(timeZone);
  const mutateTime = moment().tz(timeZone);

  let startDate = null;
  let endDate = null;

  switch (selection) {
    case "daily":
      endDate = currentTime;
      startDate = mutateTime.subtract(1, "day");
      break;
    case "weekly":
      endDate = currentTime;
      startDate = mutateTime.subtract(1, "week");
      break;
    case "monthly":
      endDate = currentTime;
      startDate = mutateTime.subtract(1, "month");
      break;
    default:
      break;
  }

  return {
    startDate: startDate.format(),
    endDate: endDate.format(),
  };
};

export const convertExcelDateToISO = (serialDate) => {

  // Initialize the base date as Jan 1, 1900, in UTC
  const baseDate = new Date(Date.UTC(1900, 0, 1)); // Use Date.UTC to create a UTC date

  // Add the serial number to the base date (subtract 1 as Excel starts counting from 1)
  baseDate.setUTCDate(baseDate.getUTCDate() + serialDate - 1);

  // Set the time to the desired time (00:00:00 UTC)
  baseDate.setUTCHours(0, 0, 0, 0);

  // Return the ISO string in UTC format
  return baseDate.toISOString();
}

export const convertExcelDateToCustomFormat = (serialDate) => {
  // Excel's base date is January 1, 1900
  const baseDate = new Date(Date.UTC(1900, 0, 1));

  // Add the serial number to the base date (subtract 1 as Excel starts counting from 1)
  baseDate.setUTCDate(baseDate.getUTCDate() + serialDate - 1);

  // Extract date and time components
  const year = baseDate.getUTCFullYear();
  const month = String(baseDate.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
  const day = String(baseDate.getUTCDate()).padStart(2, '0');
  const hours = String(baseDate.getUTCHours()).padStart(2, '0');
  const minutes = String(baseDate.getUTCMinutes()).padStart(2, '0');

  // Return the custom formatted string: YYYYMMDDHHMM
  return `${year}${month}${day}${hours}${minutes}`;
  
  // Example usage
  // const serialDate = 45788; // Example serial date
  // const formattedDate = convertExcelDateToCustomFormat(serialDate);
  // console.log(formattedDate); // Output: "202502010000"
}

