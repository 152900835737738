import { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DefaultOrganizationRolesMap from "../../globalStore/utils/DefaultOrganizationRolesMap";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import StateSelect from "../../components/Selects/StateSelect";
import {
  createOrganization,
  editOrganization,
} from "../../utils/API/Organization/Organization";

export default function EditOrganization(props) {
  const {
    isCreate,
    notificationModal,
    onHide,
    onSuccess,
    organization,
    readOnly,
  } = props;
  const theme = useTheme();
  const classes = {
    buttonContainer: {
      marginTop: '1rem',
      justifyContent: "space-between",
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  }
  const [modalState, setModalState] = useState({
    error: {
      city: false,
      name: false,
      state: false,
    },
    name: "",
    validated: false,
    ...organization,
  });
  const { address = {}, propertiesMap = {} } = modalState;
  const { address1 = "", city = "", state = "", postalCode = "" } = address;
  const { organizationDetails = "" } = propertiesMap;
  const { name } = modalState;

  function handleSubmit() {
    const { address, name, organizationId, propertiesMap = {} } = modalState;
    let body = {
      address,
      name,
      organizationId,
      propertiesMap,
      rolesMap: DefaultOrganizationRolesMap,
    };
    if (isCreate) {
      createOrganization(props, body).then((response) => {
        if (response.success) {
          let notificationModalText = isCreate
            ? "Organization Successfully Created"
            : "Location Successfully Updated";
          notificationModal(notificationModalText);
          onHide();
          onSuccess();
        } else notificationModal(`Error: ${response.error}`, true);
      });
    } else {
      editOrganization({ ...props, organizationId }, body).then((response) => {
        if (response.success) {
          let notificationModalText = isCreate
            ? "Organization Successfully Created"
            : "Location Successfully Updated";
          notificationModal(notificationModalText);
          onHide();
          onSuccess();
        } else notificationModal(`Error: ${response.error}`, true);
      });
    }
  }

  function validateFields() {
    let isValidated = true;
    const fieldsToValidate = [
      { id: "address1", value: address1 },
      { id: "city", value: city },
      { id: "name", value: name },
      { id: "postalCode", value: postalCode },
      { id: "state", value: state },
    ];

    // If something is empty, we flip isValidated to false
    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;
      if (value?.length === 0) {
        isValidated = false;

        setModalState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            [id]: true,
          },
        }));
      }
    });

    // If isValidated is never flipped, we move on
    if (isValidated) {
      handleSubmit();
    }
  }

  return (
    <Box sx={classes.root}>
      <Grid container spacing={2}>
        {/* Organization Name */}
        <Grid size={6}>
          <SimpleTextField
            error={modalState.error?.name}
            id="name"
            label="Organization Name"
            onChange={(event) => {
              setModalState((prevState) => ({
                ...prevState,
                error: { ...prevState.error, name: false },
                name: event.target.value,
              }));
            }}
            disabled={readOnly}
            required
            value={name}
          />
        </Grid>
        {/* Address */}
        <Grid size={6}>
          <SimpleTextField
            error={modalState.error?.address1}
            id="address1"
            label="Address"
            onChange={(event) => {
              const newAddress = modalState.address || {};
              newAddress.address1 = event.target.value;
              setModalState((prevState) => ({
                ...prevState,
                address: newAddress,
              }));
            }}
            disabled={readOnly}
            required
            value={address1}
          />
        </Grid>
        {/* City */}
        <Grid size={6}>
          <SimpleTextField
            error={modalState.error?.city}
            id="city"
            label="City"
            onChange={(event) => {
              const newAddress = modalState.address || {};
              newAddress.city = event.target.value;
              setModalState((prevState) => ({
                ...prevState,
                error: { ...prevState.error, city: false },
                address: newAddress,
              }));
            }}
            disabled={readOnly}
            required
            value={city}
          />
        </Grid>
        {/* State */}
        <Grid size={6}>
          <StateSelect
            error={modalState.error?.state}
            onChange={(state) => {
              let newAddress = modalState.address || {};
              newAddress.state = state;
              setModalState((prevState) => ({
                ...prevState,
                error: { ...prevState.error, state: false },
                address: newAddress,
              }));
            }}
            readOnly={readOnly}
            required={true}
            state={state}
          />
        </Grid>
        {/* Zip Code */}
        <Grid size={6}>
          <SimpleTextField
            error={modalState.error?.postalCode}
            id="postalCode"
            label="Zip Code"
            onChange={(event) => {
              const newAddress = modalState.address || {};
              newAddress.postalCode = event.target.value;
              setModalState((prevState) => ({
                ...prevState,
                address: newAddress,
              }));
            }}
            disabled={readOnly}
            required
            value={postalCode}
          />
        </Grid>
        {/* Organization Details */}
        <Grid size={12}>
          <SimpleTextField
            disabled={readOnly}
            id="postalCode"
            label="Organization Details"
            multiline
            onChange={(event) => {
              const newPropertiesMap = modalState.propertiesMap || {};
              newPropertiesMap.organizationDetails = event.target.value;
              setModalState((prevState) => ({
                ...prevState,
                propertiesMap: newPropertiesMap,
              }));
            }}
            rows={4}
            value={organizationDetails}
          />
        </Grid>

      </Grid>

      {/* Cancel and Submit Buttons */}
      {readOnly ? null : (
        <Grid container sx={classes.buttonContainer}>
          {/* Cancel Button */}
          <Grid size={4}>
            <MaterialUiButton
              color="cancel"
              fullWidth
              label="Cancel"
              onClick={() => {
                onHide();
              }}
              variant="outlined"
            />
          </Grid>

          {/* Submit Button */}
          <Grid size={4}>
            <MaterialUiButton
              color="submit"
              fullWidth
              label="Submit"
              onClick={() => {
                validateFields();
              }}
              variant="contained"
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
