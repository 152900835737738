import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";

export default function ConfirmationModalContent(props) {
  const { handleSubmit, handleCancel, content } = props;
  const classes = {
    buttonContainer: {
      display: "flex",
    },
    container: {
      textAlign: "center",
      padding: "1rem",
    },
    content: {
      marginBottom: "1rem",
    },
    text: {
      color: "black !important",
    },
  }

  return (
    <Box sx={classes.container}>
      <Grid
        container
      >
        {/* Content */}
        <Grid sx={classes.content} size={12}>
          {content}
        </Grid>

        {/* Button Container */}
        <Grid
          sx={classes.buttonContainer}
          size={12}
        >
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'space-around' }}>
        {/* Confirmation Button */}
        <Grid size={5}>
          <MaterialUiButton
            color="submit"
            cypressId={"modal-confirm-yes"}
            fullWidth
            label="YES"
            onClick={() => handleSubmit()}
          />
        </Grid>

        {/* Cancel Button */}
        <Grid size={5} sx={{ justifyContent: 'space-around' }}>
          <MaterialUiButton
            color="cancel"
            cypressId={"modal-confirm-no"}
            fullWidth
            label="NO"
            onClick={() => handleCancel()}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
