import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useHistory } from "react-router-dom";
import { useTranslation as UseTranslation } from "react-i18next";
import MaterialUiDataGridPremium from "../../../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

export default function MapTable(props) {
  const history = useHistory();

  const { facilities, mapTableData, timeZone, zones } = props;

  const { t } = UseTranslation();

  const handleClick = (row) => {
    const url = row.batchId
      ? `/batches/${row.batchId}/history/${row.assetType ? row.assetType : ""}`
      : `/assetSnapshot/${row.assetId}`;
    history.push(url);
  };

  const mapTableColumns = [
    // Date / Time
    {
      accessor: "timeOfLogLong",
      field: "timeOfLog",
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { timeOfLogLong } = data?.row || {};
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(data?.row);
            }}
          >
            {unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (row) => {
        const timeOfLogLong = row?.timeOfLogLong;
        return timeOfLogLong
          ? new Date(timeOfLogLong)
          : //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      },
      width: 200,
    },
    // Asset Tag
    {
      accessor: "tag",
      field: "tag",
      headerName: "Asset Tag",
      id: "tag",
      renderCell: (data) => {
        const { tag } = data?.row || {};
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(data?.row);
            }}
          >
            {tag ? tag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.tag ? row.tag : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.tag ? row.tag : null;
      },
      width: 150,
    },
    // Last Event
    {
      accessor: "lastEvent",
      field: "lastEvent",
      headerName: "Last Event",
      id: "lastEvent",
      renderCell: (data) => {
        const { lastEvent } = data?.row || {};
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              handleClick(data?.row);
            }}
          >
            {lastEvent ? lastEvent : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.lastEvent ? row.lastEvent : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.lastEvent ? row.lastEvent : null;
      },
    },
    // Facility Name
    {
      field: "facility",
      headerName: "Facility Name",
      id: "facility",
      renderCell: (data) => {
        const {
          facility,
          facilityId,
          latitude,
          longitude
        } = data?.row || {};
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(data?.row);
            }}
          >
            {facility
              ? facility
              : !latitude || !longitude
                ? t("dnpinput1")
                : facilityId &&
                  facilities[facilityId] &&
                  facilities[facilityId].name
                  ? facilities[facilityId].name
                  : "Not Registered"}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { facilityId } = row;
        return facilityId &&
          facilities[facilityId] &&
          facilities[facilityId].name
          ? facilities[facilityId].name
          : "Not Registered";
      },
      groupingValueGetter: (value, row) => {
        const { facilityId } = row;
        return facilityId &&
          facilities[facilityId] &&
          facilities[facilityId].name
          ? facilities[facilityId].name
          : "Not Registered";
      },
    },
    // Zone
    {
      field: "zone",
      headerName: "Zone",
      id: "zone",
      renderCell: (data) => {
        const { zone = {} } = data?.row || {};
        const { zoneId = "" } = zone;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(data?.row);
            }}
          >
            {zoneId && zones[zoneId] ? zones[zoneId].name : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneId = "" } = zone;
        return zoneId && zones[zoneId] ? zones[zoneId].name : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneId = "" } = zone;
        return zoneId && zones[zoneId] ? zones[zoneId].name : null;
      },
    },
    // Bin Location
    {
      accessor: "binLocation",
      field: "binLocation",
      headerName: "Bin Location",
      id: "binLocation",
      renderCell: (data) => {
        const { binLocation = "" } = data?.row || {};
        return (
          <div
            className="pointer"
            onClick={() => {
              handleClick(data?.row);

            }}
          >
            {binLocation ? binLocation : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.binLocation ? row.binLocation : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        return row.binLocation ? row.binLocation : null;
      },
    },
  ];

  return (
    <MaterialUiDataGridPremium
      getRowId={(row) => row.assetId}
      rows={mapTableData ? mapTableData : []}
      columns={mapTableColumns}
    />
  );
}
