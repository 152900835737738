import { getClassificationsByType } from "../../../../../utils/getClassificationsByType";
import { getCustomsByType } from "../../../../../utils/getCustomsByType";
import { trackerTableColumns } from "../../../../devices/utils/utils";
import buildAssetStatusColumns from "../../../../assets/assetStatus/Table/columns"
import buildDefaultColumns from "./displayConfigUtils";
import buildAssetHistoryColumns from "../../../../assets/assetHistory/Table/columns"
import buildAssetSnapshotColumns from "../../../../assetSnapshot/columns"
import buildInventoryHistoryColumns from "../../../../inventory/inventoryHistory/Table/columns"
import buildInventoryStatusColumns from "../../../../inventory/inventoryStatus/Table/columns"

export default function returnsDefaultDisplayColumns(props) {
  //distinguish btwn asset and inventory customs and classifications.
  //Nice source of truth location for this logic

  const { customs, classifications, name } = props;

  const assetClassifications = Object.values(classifications).filter(
    (classification) => classification.assetMode === "Asset"
  );
  const assetCustoms = customs.filter((custom) => custom.assetMode === "Asset");
  const inventoryClassifications = Object.values(classifications).filter(
    (classification) => classification.assetMode === "Inventory"
  );
  const inventoryCustoms = customs.filter(
    (custom) => custom.assetMode === "Inventory"
  );

  if (name === "AssetStatus" || name === "AssetItemAnalysis") {
    return buildDefaultColumns({
      classifications: assetClassifications,
      customs: assetCustoms,
      isInventory: false,
      isHistory: false,
    });
  } else if (name === "AssetHistory" || name === "AssetEventAnalysis") {
    return buildDefaultColumns({
      classifications: assetClassifications,
      customs: assetCustoms,
      isInventory: false,
      isHistory: true,
    });
  } else if (name === "AssetSnapshot") {
    return buildDefaultColumns({
      classifications: assetClassifications,
      customs: assetCustoms,
      isSnapshot: true,
    });
  } else if (name === "InventoryStatus" || name === "ItemAnalysis") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: false,
    });
  } else if (name === "InventoryHistory" || name === "EventAnalysis") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: true,
    });
  } else if (name === "InventorySnapshot") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isSnapshot: true,
    });
  } else if (name === "MovementHistory") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: true,
    });
  } else if (name === "Device") {
    return buildDefaultColumns({
      isDevice: true,
    });
  } else if (name === "DailyHistory") {
    return buildDefaultColumns({
      classifications: inventoryClassifications,
      customs: inventoryCustoms,
      isInventory: true,
      isHistory: true,
    });
  }
}

// This functions purpose is to check for columns that might be exported from the table
// but not saved on the clientOrganizationConfig endpoint. With this mechanism we
// can add new columns into the view settings by adding them to the tables direct columns.
export function checkForColumnsExportedByTable(table) {
  const { columns = [], type = '' } = table
  let modifiedColumns = [...columns]
  let index = columns.length
  let columnsFromTable = []

  // This if / else if block finds out what table columns need to be rendered.
  if (type === 'Device') {
    columnsFromTable = trackerTableColumns()
  } else if (type === 'AssetStatus') {
    columnsFromTable = buildAssetStatusColumns({ useTranslation: false })?.historyTableColumns || []
  } else if (
    type === 'AssetHistory' ||
    type === "AssetItemAnalysis" ||
    type === "AssetEventAnalysis" ||
    type === "EventAnalysis") {
    columnsFromTable = buildAssetHistoryColumns({ useTranslation: false })?.historyTableColumns || []
  } else if (type === 'AssetSnapshot' || type === 'InventorySnapshot') {
    columnsFromTable = buildAssetSnapshotColumns({ useTranslation: false })?.historyTableColumns || []
  } else if (type === 'DailyHistory' || type === 'InventoryHistory' || type === "ItemAnalysis") {
    columnsFromTable = buildInventoryHistoryColumns({ useTranslation: false })?.historyTableColumns || []
  } else if (type === 'InventoryStatus') {
    columnsFromTable = buildInventoryStatusColumns({ useTranslation: false })?.historyTableColumns || []
  }

  columnsFromTable.forEach((column) => {
    const { field = '', headerName = '' } = column
    const itemsIndex = columns.findIndex((element) => element.name === field)

    // Idex of -1 tells us that the column is not in the columns array
    if (itemsIndex === -1) {
      // We push the index up one to mimic displayOrder
      index++

      // We push the item into modifiedColumns
      modifiedColumns.push({
        description: field,
        displayName: headerName,
        displayOrder: index,
        displayPerspective: "all",
        displaySort: "asc",
        displayVisible: true,
        id: `${field}_all`,
        name: field,
      })
    }
  })

  return { ...table, columns: modifiedColumns }
}
