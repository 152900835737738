import { createAsyncThunk } from "@reduxjs/toolkit";

export const devices = {
  saveFilterSettings: createAsyncThunk(
    "devices/devices/saveFilterSettings",
    async (deviceTableSettings, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "", appUserId = "" } = getState().user;

      // also retrieve current filters to spread over incoming filters, just in case incoming filters do not contain all up-to-date properties
      const { filters: filtersFromStore = {} } = getState().devices;

      const filters = { ...filtersFromStore, ...deviceTableSettings };

      const data = await apiService.devices
        .saveFilterSettings({ token, appUserId }, filters)
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: { type: "error", text: data.error },
            });
          } else if (data.success) {
            return {
              success: true,
              filters: filters,
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while saving your filter settings.",
              },
            });
          }
        });
      // data.reduxRequestId = thunkAPI.requestId;
      return data;
    }
  ),
  searchDevices: createAsyncThunk(
    "devices/devices/searchDevices",
    async (deviceTableSettings = null, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;

      const { token = "", organizationId = "" } = getState().user;

      const data = await apiService.devices
        .searchDevices({ token, organizationId }, deviceTableSettings)
        .then(async (data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error,
              },
            });
          } else if (data.success) {
            const deviceAssetsMap =
              await apiService.devices.buildDeviceAssetsMap(
                { token, organizationId },
                data.assets || []
              );
            return {
              count: data.count,
              deviceAssetsMap,
              devices: data.assets,
              filters: deviceTableSettings,
              parentAssetIdTagMap: data.parentAssetIdTagMap,
              success: true,
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching your organization's devices.",
              },
            });
          }
        });

      return data;
    }
  ),
};
