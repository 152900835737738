import { useState } from "react";
import _ from "lodash";
import { Box, FormControl, TextField, useTheme } from "@mui/material/";
import { InputLabel } from "@mui/material";
import { searchAssets } from "../../../utils/API/Assets/Assets";
import { searchHistories } from "../../../utils/API/AssetHistories/AssetHistories";
import Autocomplete from "@mui/material/Autocomplete";

// TODO
// This needs to be fed "isAsset" to tell what endpoint to hit
// This also needs to be fed "isInventory" to tell what types of assets are to be returned
// But the isInventory logic needs to be replaced with 'assetType' as the 'isInventory' limits us
// to just two types of assets.
export default function BinSelector(props) {
  const {
    apiUrl,
    assetType = 'Inventory',
    isAsset = false,
    isAssetAnalysis,
    isInventory = true,
    isItemAnalysis,
    labelPlacement = "top",
    onChange = () => { },
    onError = () => { },
    organizationId,
    size = "small",
    token,
    value = null,
    variant,
  } = props;
  const theme = useTheme();
  const { palette = {} } = theme;
  const { typography = {} } = palette;
  const [options, setOptions] = useState([]);

  const debounce_try = _.debounce((inputValue) => {
    if (isAssetAnalysis || isItemAnalysis || isAsset) {
      searchAssets(
        { apiUrl, token, organizationId, isInventory },
        { filters: { binLocationWildCard: inputValue, limit: 300 } }
      ).then((results) => {
        if (results.error) {
          onError(results.error);
        }
        const options =
          results.assets && results.assets.length
            ? [
              ...new Set(
                results.assets
                  .filter((asset) => asset.zone.binLocation)
                  .map((asset) => asset.zone.binLocation)
              ),
            ].map((binLocation) => {
              return {
                label: binLocation,
                value: binLocation,
              };
            })
            : [];

        setOptions(options);
      });
    } else {
      searchHistories(
        { apiUrl, token, organizationId, isInventory },
        { filters: { binLocationWildCard: inputValue, limit: 300 } }
      ).then((results) => {
        if (results.error) {
          onError(results.error);
        }
        const options =
          results.assetHistories && results.assetHistories.length
            ? [
              ...new Set(
                results.assetHistories
                  .filter((history) => history.binLocation)
                  .map((history) => history.binLocation)
              ),
            ].map((binLocation) => {
              return {
                label: binLocation,
                value: binLocation,
              };
            })
            : [];

        setOptions(options);
      });
    }
  }, 1000);

  return (
    <Box
      sx={
        labelPlacement === "start"
          ? {
            display: "flex",
            alignItems: "center",
          }
          : {}
      }
    >
      <Box
        sx={
          labelPlacement === "start"
            ? {
              display: "flex",
              alignItems: "center",
            }
            : {}
        }
      >
        <Box
          sx={{
            color: typography?.secondary,
            display: "flex",
            fontFamily: "Lato",
            fontWeight: "bold !important",
            justifyContent: "space-between",
            lineHeight: 1,
            marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
            marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
            marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
          }}
        >
          <InputLabel
            htmlFor="inventory-ea-txtselect-binlocation"
            sx={{
              color: typography?.secondary,
              fontFamily: "Lato",
              fontWeight: "bold",
            }}
          >
            Bin Location
          </InputLabel>
        </Box>
      </Box>
      <FormControl
        className="bin-select"
        sx={{ width: "100%" }}
        variant={variant ? variant : "standard"}
      >
        {/* https://mui.com/material-ui/react-autocomplete/#search-as-you-type */}
        <Autocomplete
          autoComplete
          disableCloseOnSelect
          filterOptions={(x) => x}
          filterSelectedOptions
          getOptionLabel={(option) => option?.label}
          id="inventory-ea-txtselect-binlocation"
          includeInputInList
          multiple
          noOptionsText="Start typing to search bins"
          onChange={(event, value) => onChange(value)}
          onClose={() => setOptions([])}
          onInputChange={(event, newInputValue) => {
            if (
              newInputValue !== "undefined" &&
              newInputValue.trim().length !== 0
            ) {
              debounce_try(newInputValue);
            }
          }}
          options={options}
          renderInput={(params) => <TextField {...params} fullWidth />}
          size={size}
          sx={{ width: "100%" }}
          value={value}
        />
      </FormControl>
    </Box>
  );
}
