import { getClassificationsByType } from "../../../utils/getClassificationsByType";
import { getCustomsByType } from "../../../utils/getCustomsByType";
import { isEqual } from "lodash";
import { useHistory } from "react-router-dom";
import buildColumns from "../inventoryHistory/Table/columns";
import Grid from "@mui/material/Grid2";
import InventoryStatusBuildColumns from "../inventoryStatus/Table/columns";
import QueryBuilder from "../../../components/QueryBuilder/QueryBuilder";

export default function InventoryAnalysis(props) {
  const {
    apiUrl,
    handleSave,

    // The things this boolean does is determine what filters are appended, what endpoint we hit, and the id to be used for the row.
    // Everything else is the same
    isItemAnalysis = false,
    organizationId,
    timeZone,
    token,
    userId,
    useSelector = () => { },
  } = props;

  // OK to destructure, as no changes to org data takes place in this page
  const { zones, facilities, customs, classifications } =
    useSelector((state) => state.organization, isEqual) || {};

  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const history = useHistory();
  const specificConfig = isItemAnalysis
    ? displayConfigs.find((element) => element.type === "ItemAnalysis")
    : displayConfigs.find((element) => element.type === "EventAnalysis");
  const columnOrder = specificConfig?.columns || null;

  const inventoryClassifications = getClassificationsByType({ assetMode: "Inventory", isObject: false });
  const inventoryCustoms = getCustomsByType({ assetMode: "Inventory" });


  const { historyTableColumns } = buildColumns({
    apiUrl,
    classifications: inventoryClassifications,
    columnOrder,
    customs: inventoryCustoms,
    facilities,
    handleSave,
    history,
    timeZone,
    token,
    userId,
    zones,
  });

  const { historyTableColumns: inventoryTableColumns } =
    InventoryStatusBuildColumns({
      apiUrl,
      classifications,
      columnOrder,
      customs,
      facilities,
      handleSave,
      history,
      timeZone,
      token,
      userId,
      zones,
    });

  return (
    (<Grid
      container
      className="inventory-analyze"
      style={{
        marginTop: "1rem",
        width: "84vw",
      }}
    >
      <QueryBuilder
        {...props}
        columns={isItemAnalysis ? inventoryTableColumns : historyTableColumns}
        defaultFilters={isItemAnalysis ? { assetMode: 'Inventory', selectedTable: 'Assets' } : { assetMode: 'Inventory', selectedTable: 'AssetHistories' }}
      />
    </Grid>)
  );
}
