import { useSelector } from "react-redux";
import { isEqual } from "lodash";

// This component was built because we invoke the same logic in multiple
// areas. (Around 5 places at the time) Also this method allows us to include
// the properties in the displayColumns on the settings page

export function getClassificationsByType(props) {
  const {
    classifications = {},
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useSelector((state) => state?.organization, isEqual);
  // Currently the only two assetModes we use for this is "Asset" and "Inventory"
  const { assetMode: assetModeFromProps = 'Asset', isObject = false } = props

  if (isObject) {
    let classificationsByAssetMode = {}

    Object.keys(classifications).forEach((key) => {
      let classification = classifications[key];
      if (classification.assetMode === assetModeFromProps || !classification.assetMode) {
        classificationsByAssetMode = {
          ...classificationsByAssetMode,
          [key]: classification,
        };
      }
    });

    return classificationsByAssetMode
  } else {
    const filteredClassifications = Object.values(classifications || [])
      .filter((classification) => classification.assetMode === assetModeFromProps)

    return filteredClassifications
  }

}