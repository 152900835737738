import { useSelector } from "react-redux";
import { isEqual } from "lodash";

// This component was built because we invoke the same logic in multiple
// areas. (Around 5 places at the time) Also this method allows us to include
// the properties in the displayColumns on the settings page

export function getCustomsByType(props) {
  const { assetMode: assetModeFromProps = 'Asset' } = props
  const {
    customs =  [],
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useSelector((state) => state?.organization, isEqual);
  const filteredCustoms = customs.filter((custom) => custom.assetMode === assetModeFromProps || !custom.assetMode);

  return filteredCustoms

}