import { unix_utc_to_local_12_hour_clock } from "../../../utils/date_time_utils";
import { t } from "i18next";

export async function searchDeviceAssets(
  props,
  joined_device_asset_ids = "",
  limit = 300
) {
  const { apiUrl, token, organizationId } = props;
  const elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            terms: {
              parent_id: joined_device_asset_ids,
            },
          },
        ],
      },
    },
    sort: [
      {
        time_of_log: "asc",
      },
      {
        tag: "desc",
      },
    ],
    limit: limit,
  };

  const results = await fetch(`${apiUrl}assets/search?includeConsoleExtras=true`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
}

export async function buildDeviceAssetsMap(props, deviceList) {
  const deviceAssetIds =
    deviceList.length > 0
      ? deviceList
        .map((d) => {
          if (d.assetId) {
            return d.assetId;
          }
          return null;
        })
        .filter((el) => el !== null)
      : null;

  if (deviceAssetIds) {
    const deviceAssetsMap = await searchDeviceAssets(
      props,
      deviceAssetIds
    ).then((res) => {
      if (res.error) {
        return { error: "Error fetching device assets." };
      }
      let deviceAssetsMap = {};
      res.assets.forEach((ass) => {
        if (!deviceAssetsMap[ass.parentId]) {
          deviceAssetsMap[ass.parentId] = [];
        }
        deviceAssetsMap[ass.parentId].push(ass);
      }, {});

      return {
        ...deviceAssetsMap,
      };
    });
    return deviceAssetsMap;
  }

  return null;
}

export const unassignDevices = async (
  props,
  selectedDevices = {},
  device_asset_map = {}
) => {
  const { apiUrl, token } = props;
  const deviceAssets = Object.keys(device_asset_map)
    .filter((d) => selectedDevices[d])
    .map((d) => {
      return {
        deviceId: d,
        associatedAssets: device_asset_map[d],
      };
    });

  const iterateAndFetch = deviceAssets.map(async (d) => {
    const unassignAssets = d.associatedAssets.map(async (ass) => {
      const unassign = await fetch(`${apiUrl}assets/${ass.assetId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify({ parentId: null }),
      })
        .then((res) => res.json())
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return {
            error: err,
          };
        });
      return unassign;
    });

    const results = await Promise.all(unassignAssets).then(
      async (completed) => {
        // if any failed, then return error message from backend
        if (completed.some((res) => res.error)) {
          return completed.find((res) => res.error);
        }
        const setStatus = await fetch(`${apiUrl}assets/${d.deviceId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
          body: JSON.stringify({ device: { status: "Unassigned" } }),
        })
          .then((res) => res.json())
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return {
              error: err,
            };
          });
        return setStatus;
      }
    );
    return results;
  });

  return await Promise.all(iterateAndFetch).then((completed) => {
    // if any failures, return error message from backend
    if (completed.some((res) => res.error)) {
      return completed.find((res) => res.error);
    }
    return completed;
  });
};

export const dissociateDevices = async (props, selectedDevicesIds) => {
  const { apiUrl, token } = props;
  const iterateAndFetch = selectedDevicesIds.map(async (currentDeviceId) => {
    const results = await fetch(`${apiUrl}assets/${currentDeviceId}/action`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        action: "dissociate",
        propertiesMap: {
          formData: [],
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return {
          error: err,
        };
      });

    return results;
  });

  return await Promise.all(iterateAndFetch).then((completed) => {
    // if any failures, return error message from backend
    if (completed.some((res) => res.error)) {
      return completed.find((res) => res.error);
    }
    return completed;
  });
};

export const trackerTableColumns = (props) => {
  const { timeZone = '', history = [], parentAssetIdTagMap = {} } = props || {};
  return [
    {
      field: "timeCreatedLong",
      groupable: false,
      headerName: "Created Date",
      id: "timeCreatedLong",
      renderCell: ({ row }) => {
        const { id, timeCreatedLong } = row;
        return (
          <div
            key={id}
          >
            {timeCreatedLong
              ? unix_utc_to_local_12_hour_clock(timeCreatedLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (value, row) => {
        const timeCreatedLong = row.timeCreatedLong;
        return timeCreatedLong
          ? new Date(timeCreatedLong)
          : //furthest date in the future. We want records without a timeCreatedLong to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      },
    },
    {
      accessor: "tag",
      field: "tag",
      flex: 2,
      headerName: "Tracker Identifier",
      id: "tag",
      valueGetter: (value, row) => row?.tag || "",
      groupingValueGetter: (value, row) => row?.tag || null,
      width: 150,
    },
    {
      field: "timeModifiedLong",
      groupable: false,
      headerName: "Last Modified Date",
      id: "timeModifiedLong",
      renderCell: ({ row }) => {
        const { id, timeModifiedLong } = row;
        return (
          <div
            key={id}
          >
            {timeModifiedLong
              ? unix_utc_to_local_12_hour_clock(timeModifiedLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (value, row) => {
        const timeModifiedLong = row.timeModifiedLong;
        return timeModifiedLong
          ? new Date(timeModifiedLong)
          : //furthest date in the future. We want records without a timeModifiedLong to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      },
    },
    //commented out until descendentsList property is added
    {
      field: "parentId",
      groupable: false,
      headerName: "Associated Item",
      id: "parentId",
      renderCell: ({ row }) => {

        return (
          <div
            onClick={() => {
              if (parentAssetIdTagMap[row.parentId]) {
                const {
                  parentId,
                } = row

                // Currently no one is using batches. So for the moment, we will not be redirecting to the batch snapshot
                // page. But we do need to look at the two pages and see if we need both or if we can consolidate.
                if (parentId) {
                  const url = `/assetSnapshot/${parentId}`;
                  history.push(url);
                }
              }
            }}
          >
            {parentAssetIdTagMap[row.parentId]
              ? parentAssetIdTagMap[row.parentId]
              : "-"}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return parentAssetIdTagMap[row.parentId]
          ? parentAssetIdTagMap[row.assetId]
          : "";
      },
    },
    {
      accessor: "lastEvent",
      field: "lastEvent",
      flex: 1,
      headerName: "Status",
      id: "lastEvent",
      renderCell: (data) => {
        if (data.rowNode.type === "group" && data.field === "lastEvent" || data.rowNode.type === "pinnedRow") {
          return null
        }
        else if (data.rowNode.type === "group") {
          return data.value
        }
      },
      valueGetter: (value, row) => {
        return row.device?.hasDescendents ? "Associated" : "Unassociated";
      },
      groupingValueGetter: (value, row) => {
        return row.device?.hasDescendents ? "Associated" : "Unassociated";
      }
    },
    {
      field: "platform",
      flex: 1,
      headerName: "Manufacturer",
      id: "platform",
      renderCell: (data) => { if (data.rowNode.type === "group") return data.value },
      valueGetter: (value, row) => row?.device?.platform || t("dnpinput1"),
      groupingValueGetter: (value, row) => row?.device?.platform || null,
    },
    {
      accessor: "assetType",
      field: "assetType",
      flex: 1,
      headerName: "Tracker Type",
      id: "assetType",
      renderCell: (data) => { if (data.rowNode.type === "group") return data.value },
      valueGetter: (value, row) => row?.assetType,
      groupingValueGetter: (value, row) => row?.assetType || null,
    },
    {
      availableAggregationFunctions: ["avg", "min", "max"],
      field: "batteryLife",
      flex: 1,
      headerName: "Battery Life (%)",
      id: "batteryLife",
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow") return data.value


        return (
          <div>
            {row.device?.batteryPercent ? `${row.device?.batteryPercent / 100}%` : row.propertiesMap?.battery ? `${row.propertiesMap.battery / 100}%` : t("dnpinput1")}
          </div>
        );
      },
      type: "number",
      valueGetter: (value, row) => row?.device?.batteryPercent / 100 || row?.propertiesMap?.battery / 100 || null,
      groupingValueGetter: (value, row) => {
        return row.device?.batteryPercent ? row.device?.batteryPercent / 100 : row.propertiesMap?.battery ? row.propertiesMap.battery / 100 : null
      }
    },
    {
      field: "speed",
      flex: 1,
      headerName: "Speed (m/h)",
      id: "speed",
      renderCell: (data) => { if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow") return data.value },
      // type: "number",
      valueGetter: (value, row) => row?.device?.speed || t("dnpinput1"),
      groupingValueGetter: (value, row) => row?.device?.speed || null
    },
    {
      field: "altitude",
      flex: 1,
      headerName: "Altitude (m above sea level)",
      id: "altitude",
      renderCell: (data) => { if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow") return data.value },
      // type: "number",
      valueGetter: (value, row) => row?.device?.altitude || t("dnpinput1"),
      groupingValueGetter: (value, row) => row?.device?.altitude || null
    },
    {
      field: "heading",
      flex: 1,
      headerName: "Heading (°)",
      id: "heading",
      renderCell: (data) => { if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow") return data.value },
      // type: "number",
      valueGetter: (value, row) => row?.device?.heading || t("dnpinput1"),
      groupingValueGetter: (value, row) => row?.device?.heading || null
    },
    {
      field: "temp",
      flex: 1,
      headerName: "Temperature (°C)",
      id: "temp",
      renderCell: (data) => { if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow") return data.value },
      // type: "number",
      valueGetter: (value, row) => row?.device?.temp || t("dnpinput1"),
      groupingValueGetter: (value, row) => row?.device?.temp || null
    },
    {
      field: "humidity",
      flex: 1,
      headerName: "Humidity (%)",
      id: "humidity",
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow") return data.value
        return (
          <div>
            {row.device?.humidity ? `${row.device.humidity}%` : t("dnpinput1")}
          </div>
        );
      },
      // type: "number",
      valueGetter: (value, row) => row?.device?.humidity || null,
      groupingValueGetter: (value, row) => row?.device?.humidity ? `${row.device.humidity}%` : null
    },
  ];
}
