import { useState } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { isEqual } from "lodash";
import { isValidDateObject } from "../../globalStore/utils/helpers";
import { searchAssets } from "../../utils/API/Assets/Assets";
import { searchHistories } from "../../utils/API/AssetHistories/AssetHistories";
import { useSelector } from "react-redux";
import BinSelector from "../Selects/BinSelector/BinSelector";
import ClassificationFilters from "../Selects/ClassificationsSelector/ClassificationFilters";
import CustomFilters from "../Selects/CustomsSelector/CustomFilters";
import EventAndUserFilters from "./EventAndUserFilters";
import Grid from "@mui/material/Grid2";
import LXDatePicker from "../Forms/FieldTypes/DatePicker";
import MaterialUiButton from "../Buttons/MaterialUiButton/MaterialUiButton";
import RadioList from "../Forms/FieldTypes/RadioList";
import SimpleSelect from "../Forms/FieldTypes/Select";
import SimpleTextField from "../Forms/FieldTypes/TextField";

export default function QueryBuilderFilters(props) {
  const {
    apiUrl,
    classifications,
    customs,
    isAssetsTable,
    organizationId,
    setState,
    showAssetMode = true,
    showResultLimit = true,
    showSelectTable = true,
    state,
    token
  } =
    props;
  const { filters = {} } = state;
  const {
    assetMode = 'Assets',
    binLocations,
    endDate,
    limit,
    selectedAssembly,
    selectedTable,
    startDate,
    tag,
  } = filters;
  const { organization } = useSelector(
    (state) => state.organization,
    isEqual
  );
  const [canSubmit, setCanSubmit] = useState(true);
  const [classificationsFromFilters, setClassificationsFromFilters] = useState([])

  function renderSearchValues() {
    // I think we can get rid of isAsset from the component. But im going to get this going for now...
    // Hot swap this as a reusable component. And then submit another PR to possibly get rid of isAsset.

    if (isAssetsTable) {
      searchAssets({ ...props, skip: 0 }, state).then(
        (res) => {
          // Filter results based on selected assembly
          if (selectedAssembly?.value) {
            const resFilteredByAssembly = res?.assets?.filter((asset) =>
              asset?.assemblies?.includes(selectedAssembly.value)
            );
            setState((prevState) => ({
              ...prevState,
              assetHistories: resFilteredByAssembly,
              realCount: res?.count,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              assetHistories: res?.assets,
              realCount: res?.count,
            }));
          }
        }
      );
    } else if (!isAssetsTable) {
      searchHistories({ ...props, skip: 0 }, state).then(
        (res) => {
          //filter results based on selected assembly.
          if (selectedAssembly?.value) {
            const resFilteredByAssembly = res?.assetHistories.filter(
              (asset) => {
                return asset.assemblyId === selectedAssembly.value;
              }
            );
            setState((prevState) => ({
              ...prevState,
              assetHistories: resFilteredByAssembly,
              realCount: res?.count,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              assetHistories: res?.assetHistories,
              realCount: res?.count,
            }));
          }
        }
      );
    }
  }

  // Used for sorting the options in the select
  function compareFn(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    } else {
      return 0;
    }
  }

  const fieldStyle = { margin: "1rem 0" };

  return (
    (<Card>
      <CardContent>
        <Grid container>
          {/* Select Table / Limit / Asset Type */}
          {showSelectTable || showResultLimit ? <Grid item size={12}>

            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>

              {/* Select Table */}
              {showSelectTable ? <RadioList
                label="Select Table"
                onChange={(e) => setState((prevState) => ({ ...prevState, assetHistories: [], filters: { ...prevState.filters, selectedTable: e.target.value } }))}
                options={[
                  { label: "Assets Table", value: "Assets" },
                  { label: "Asset History Table", value: "AssetHistories" },
                ]}
                row
                value={selectedTable}
              /> : null}

              {/* Limit */}
              {showResultLimit ? <SimpleTextField
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      limit: event.target.value,
                    },
                  }))
                }
                fullWidth
                size="large"
                type="number"
                value={limit}
                label="Results Limit (max 10000)"
              /> : null}

              {/* Asset Mode */}
              {showAssetMode ?
                <Grid size={3}>
                  <SimpleSelect
                    label="Asset Mode"
                    fullWidth
                    onChange={(e) => {
                      
                      // Because Customs and Classifications are dependent on the assetMode, we need to clear them out when the assetMode changes
                      setState(prevState => ({
                        ...prevState,
                        assetHistories: [],
                        filters: {
                          ...prevState.filters,
                          assetMode: e.target.value,
                          classifications: [],
                          customs: [],
                        }
                      }))

                      // Clear out the classificationsFromFilters
                      setClassificationsFromFilters([])
                    }}
                    options={[
                      { label: "All Assets", value: "AllAssets" },
                      { label: "Assets", value: "Assets" },
                      { label: "Container", value: "Container" },
                      { label: "Device", value: "Device" },
                      { label: "Inventory", value: "Inventory" },
                      { label: "Managed", value: "Managed" },
                      { label: "Product", value: "Product" },
                    ]}
                    size="large"
                    variant="outlined"
                    value={assetMode}
                  />
                </Grid>
                : null}

            </Box>
          </Grid> : null}


          {/* From Date / To Date / Category / Display Button */}
          <Grid sx={{ padding: "0 1rem" }} size={3}>
            {/* From Date */}
            <Box sx={fieldStyle}>
              <LXDatePicker
                format="MM/dd/yyyy"
                label="From Date (required)"
                onChange={(date) => {
                  //if endDate is before date, set canSubmit to false. Dont set canSubmit to false if date is the same day as enddate
                  if (
                    date > endDate &&
                    date.toDateString() !== endDate.toDateString()
                  ) {
                    setCanSubmit(false);
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, startDate: date },
                    }));
                  } else {
                    //user can only submit if both dates are valid
                    setCanSubmit(
                      isValidDateObject(date) && isValidDateObject(endDate)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, startDate: date },
                    }));
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: !canSubmit && "Invalid date",
                    InputLabelProps: {
                      shrink: false,
                      style: { color: "transparent" },
                    },
                    label: "From Date",
                    size: "large",
                  },
                }}
                value={startDate}
              />
            </Box>
            {/* To Date */}
            <Box sx={fieldStyle}>
              <LXDatePicker
                format="MM/dd/yyyy"
                label="To Date (required)"
                onChange={(date) => {
                  //if enddate is before date, set canSubmit to false. Dont set canSubmit to false if date is the same day as enddate
                  if (
                    date < startDate &&
                    date.toDateString() !== startDate.toDateString()
                  ) {
                    setCanSubmit(false);
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, endDate: date },
                    }));
                  } else {
                    //user can only submit if both dates are valid
                    setCanSubmit(
                      isValidDateObject(date) && isValidDateObject(startDate)
                    );
                    setState((prevState) => ({
                      ...prevState,
                      filters: { ...prevState.filters, endDate: date },
                    }));
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: !canSubmit && "Invalid date",
                    InputLabelProps: {
                      shrink: false,
                      style: { color: "transparent" },
                    },
                    label: "To Date",
                    size: "large",
                  },
                }}
                value={endDate}
              />
            </Box>

            {/* Tag ID */}
            <Box sx={fieldStyle}>
              <SimpleTextField
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    filters: {
                      ...prevState.filters,
                      tag: event.target.value,
                    },
                  }))
                }
                fullWidth
                size="large"
                value={tag}
                label="Tag Id"
              />
            </Box>

            {/* Display Button */}
            <Box sx={{ marginTop: "1rem" }}>
              <MaterialUiButton
                label="Display"
                data-testid="Display Button"
                onClick={() => renderSearchValues()}
              />
            </Box>
          </Grid>

          {/* Row/Bin / Event */}
          <Grid sx={{ padding: "0 1rem" }} size={3}>

            {/* Event */}
            {!isAssetsTable ? (
              <EventAndUserFilters
                compareFn={compareFn}
                fieldStyle={fieldStyle}
                organization={organization}
                setState={setState}
                state={state}
              />
            ) : null}

            {/* Row/Bin */}
            <Box sx={fieldStyle}>
              <BinSelector
                apiUrl={apiUrl}
                isInventory={true}
                isAsset={isAssetsTable}
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    filters: { ...prevState.filters, binLocations: event },
                  }))
                }
                organizationId={organizationId}
                size="large"
                showCheckboxes={false}
                token={token}
                value={binLocations}
              />
            </Box>
          </Grid>

          {/* Classification Filters */}
          <ClassificationFilters
            classificationFromFiltersFromProps={classificationsFromFilters}
            setClassificationFromFiltersFromProps={setClassificationsFromFilters}
            classifications={classifications}
            state={state}
            setState={setState}
          />

          {/* Custom Filters */}
          <CustomFilters
            customs={customs}
            state={state}
            setState={setState}
          />
        </Grid>
      </CardContent>
    </Card>)
  );
}
