import { CssBaseline, Typography, useTheme } from "@mui/material";
import { hideAlert } from "../../globalStore/slices/alert/alertSlice";
import { useFreshDeskAuth } from "../../app/FreshDesk/useFreshDeskAuth";
import { useSelector, useDispatch } from "react-redux";
import FreshDeskWidget from "../../app/FreshDesk/FreshDeskWidget";
import MaterialConfirmationModal from "../Modals/MaterialConfirmationModal";
import SideNav from "./SideNav/SideNav";
import TopNav from "./TopNav/TopNav";

export default function AuthLayout(props) {
  const theme = useTheme();
  const classes = {
    root: {
      backgroundColor: "#E6E7E8",
      display: "flex",
      fontSize: "1rem",
      height: "100vh",
      lineHeight: "1.5",
      overflow: "auto",
    },
    toolbar: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      height: "100vh",
      minHeight: "100vh",
      padding: theme.spacing(3, 5, 3, 3),
      paddingTop: "64px",
      width: '90%',
    },
  };
  const sideNavWidth = 90;
  const appBarHeight = 56;
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const token = useSelector((state) => state.user.token);

  useFreshDeskAuth(token);

  return (
    <>
      <CssBaseline />
      <Typography component="div" sx={classes.root}>
        <FreshDeskWidget />
        <SideNav sideNavWidth={sideNavWidth} appBarHeight={appBarHeight} />

        <TopNav {...props} />
        <Typography component="main" sx={classes.content}>
          <MaterialConfirmationModal
            closeModal={() => {
              dispatch(hideAlert({}));
            }}
            content={alert.text}
            modalOpen={alert.show}
            severity={alert.type || "error"}
          />

          {props.children ? props.children : null}
          {/* Bottom padding for help desk widget */}
          <div style={{ paddingBottom: "75px" }}></div>
        </Typography>
      </Typography>
    </>
  );
}
