import { getClassificationsByType } from "../../../utils/getClassificationsByType";
import { getCustomsByType } from "../../../utils/getCustomsByType";
import { isEqual } from "lodash";
import { useHistory } from "react-router-dom";
import AssetStatusBuildColumns from "../assetStatus/Table/columns";
import buildColumns from "../assetHistory/Table/columns";
import Grid from "@mui/material/Grid2";
import QueryBuilder from "../../../components/QueryBuilder/QueryBuilder";

export default function AssetAnalysis(props) {
  const {
    apiUrl,
    handleSave,
    // The things this boolean does is determine what filters are appended, what endpoint we hit, and the id to be used for the row.
    // Everything else is the same
    isAssetAnalysis = false,
    timeZone,
    token,
    userId,
    useSelector = () => { },
  } = props;

  // OK to destructure, as no changes to org data takes place in this page
  const { zones, facilities } =
    useSelector((state) => state.organization, isEqual) || {};

  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const history = useHistory();
  const specificConfig = isAssetAnalysis
    ? displayConfigs.find((element) => element.type === "AssetItemAnalysis")
    : displayConfigs.find((element) => element.type === "AssetEventAnalysis");
  const columnOrder = specificConfig?.columns || null;

  const assetClassifications = getClassificationsByType({
    assetMode: "Asset",
    isObject: false
  });
  const assetCustoms = getCustomsByType({
    assetMode: "Asset",
  });

  const { historyTableColumns: assetEventAnalysisColumns } = buildColumns({
    apiUrl,
    classifications: assetClassifications,
    columnOrder,
    customs: assetCustoms,
    facilities,
    handleSave,
    history,
    timeZone,
    token,
    userId,
    zones,
  });

  const { historyTableColumns: assetItemAnalysisColumns } =
    AssetStatusBuildColumns({
      apiUrl,
      classifications: assetClassifications,
      columnOrder,
      customs: assetCustoms,
      facilities,
      handleSave,
      history,
      timeZone,
      token,
      userId,
      zones,
    });

  return (
    <Grid
      container
      className="asset-analyze"
      style={{
        marginTop: "1rem",
        margin: "0 auto",
        width: "84vw",
      }}
    >
      <QueryBuilder
        {...props}
        columns={isAssetAnalysis ? assetItemAnalysisColumns : assetEventAnalysisColumns}
        defaultFilters={isAssetAnalysis ? { assetMode: 'Assets', selectedTable: 'Assets' } : { assetMode: 'Assets', selectedTable: 'AssetHistories' }}
      />
    </Grid>
  );
}
