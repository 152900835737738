import { createSlice } from "@reduxjs/toolkit";

export const recentsSlice = createSlice({
    //create recents for assets, inventory assets, and facilities for storing their ids or selected facility data for facilities
    name: "recents",
    initialState: {
        assets: [],
        inventoryAssets: [],
        facilities: [],
    },
    reducers: {
        addRecentAsset: (state, action) => {
            //  add the assetId to the recents array and make sure that the assetId is not already in the recents array
            state.assets = state.assets.filter((currAsset) => currAsset?.assetId !== action.payload.assetId);
            if (state.assets.length >= 5) {
                state.assets.pop();
            }
            state.assets = [{ assetId: action.payload.assetId, assetTag: action.payload.assetTag }, ...state.assets];
        },
        addRecentInventoryAsset: (state, action) => {
            state.inventoryAssets = state.inventoryAssets.filter((currInvAsset) => currInvAsset !== action.payload.assetId);
            if (state.inventoryAssets.length >= 5) {
                state.inventoryAssets.pop();
            }
            state.inventoryAssets = [{ assetId: action.payload.assetId, assetTag: action.payload.assetTag }, ...state.inventoryAssets];
        },
        addRecentFacility: (state, action) => {
            //check facilities to see if the facility is already in the recents array
            //if it is, then remove it and add it to the front of the array
            //if it is not, then add it to the front of the array
            state.facilities = state.facilities.filter((currFacility) => currFacility.facilityName !== action.payload.facilityName);
            if (state.facilities.length >= 5) {
                state.facilities.pop();
            }
            state.facilities = [{ facilityName: action.payload.facilityName, facilityData: action.payload.facilityData }, ...state.facilities];
        }
    },


})

const { addRecentAsset, addRecentInventoryAsset, addRecentFacility } = recentsSlice.actions;
export { addRecentAsset, addRecentInventoryAsset, addRecentFacility };
export default recentsSlice.reducer;
