import { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import { RetrieveOrganization } from "../../utils/apiCalls";
import { uploadImage, deleteImage, editImage } from "./utils";
import ItemLevelDataElementSelect from "./ItemLevelDataElementSelect";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import ImagesDisplayNSave from "../../components/Images/ImagesDisplayNSave";
import ProductInformation from "./ProductInformation";
import { useTheme } from '@mui/material/styles';

export default function EditProduct(props) {
  const theme = useTheme();
  const classes = {
    buttonContainer: {
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    buttonGroup: {
      marginTop: "1rem",
    },
    control: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: "100%",
    },
    link: {
      "&:hover": {
        textDecoration: "none",
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    select: {
      marginBottom: "1rem",
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  };


  const {
    apiUrl,
    dispatchGlobal,
    isCreate,
    modalClose,
    readOnly = false,
    redirect,
    selectedProduct,
    token,
    updateProducts,
  } = props;
  const [state, setState] = useState({
    addField: false,
    customDataFields:
      selectedProduct && selectedProduct.propertiesMap
        ? selectedProduct.propertiesMap.customDataFields
        : [],
    deleteCustomField: false,
    description: {
      id: "description",
      value: selectedProduct.description || "",
    },
    images: selectedProduct.images || [],
    imagesToDelete: [],
    imagesToEdit: {},
    selectedItemLevelDataElements: selectedProduct.propertiesMap
      ? selectedProduct.propertiesMap.itemLevelDataElements
      : [],
    name: { error: false, id: "name", value: selectedProduct.name || "" },
    tag: { error: false, id: "tag", value: selectedProduct.tag || "" },
    newFieldData: "",
    newFieldTitle: "",
    productId: selectedProduct.productId || "",
    productType: {
      error: false,
      id: "productType",
      value: selectedProduct.productType || "",
    },
    propertiesMap: selectedProduct.propertiesMap
      ? selectedProduct.propertiesMap
      : {},
    urlBase: {
      error: false,
      id: "urlTarget",
      // value: selectedProduct.urlBase || "https://connectx-staging.locatorx.com",
      value: process.env.REACT_APP_URL_BASE,
    },
  });
  const [checkImages, setCheckImages] = useState(false);
  const [addProductLevelDataElement, setAddProductLevelDataElement] =
    useState(false);
  const [addItemLevelDataElement, setAddItemLevelDataElement] = useState(false);
  const [itemLevelDataElements, setItemLevelDataElements] = useState(
    props.itemLevelDataElements || []
  );
  const [productTypes, setProductTypes] = useState(props.productTypes || []);
  const {
    customDataFields = [],
    description = "",
    images = [],
    imagesToDelete = [],
    imagesToEdit = {},
    name,
    productId,
    productType,
    selectedItemLevelDataElements,
    tag = "",
    urlBase,
  } = state;

  useEffect(() => {
    if (
      selectedProduct &&
      selectedProduct.propertiesMap &&
      selectedProduct.propertiesMap.itemLevelDataElements &&
      selectedProduct.propertiesMap.itemLevelDataElements.length > 0
    ) {
      setAddItemLevelDataElement(true);
    }
    if (redirect) {
      RetrieveOrganization({ ...props }, (organization) => {
        const { propertiesMap = {} } = organization;
        setProductTypes(propertiesMap.productTypes.filter((t) => t != null));
        setItemLevelDataElements(propertiesMap.itemLevelDataElements);
      });
    }
  }, [props, redirect, selectedProduct]);


  const productCategoryOptions = () => {
    const productCategories = [];
    productTypes.map((element) => {
      if (typeof element === "string") {
        productCategories.push({ value: element, label: element });
      } else {
        const { id, label } = element;
        productCategories.push({ value: id, label: label });
      }
      return null;
    });
    return productCategories;
  };

  function deleteImages() {
    imagesToDelete.forEach((image) => {
      const { imageId } = image;
      deleteImage({ ...props, productId, imageId });
    });
  }

  function uploadImages(images, Id) {
    const newProductId = isCreate ? Id : productId;
    images.forEach((image) => {
      uploadImage({ ...props, productId: newProductId, ...image });
    });
  }

  function editImages(images) {
    images.forEach((imageId) => {
      const { name } = imagesToEdit[imageId];
      editImage({ ...props, productId, imageId, name });
    });
  }

  function handleSubmit() {
    const { confirmationModal, onSuccess } = props;
    const {
      customDataFields = [],
      description,
      name,
      productType,
      propertiesMap,
      tag,
      urlBase,
    } = state;
    let body = {
      description: description.value,
      name: name.value,
      productId: productId.value,
      productType: productType.value,
      propertiesMap: { ...propertiesMap },
      tag: tag.value,
      target: "/",
      urlBase: urlBase.value,
    };

    // This puts the custom data fields into both the propertiesMap and
    // the adds customDataFields into the propertiesMap
    if (customDataFields) {
      customDataFields.forEach((element) => {
        body.propertiesMap[element.title] = element.value;
      });
      body.propertiesMap.customDataFields = customDataFields;
    }

    body.propertiesMap.itemLevelDataElements = selectedItemLevelDataElements;

    fetch(`${apiUrl}products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const { products = [] } = res;
        const newImages = images.filter((item) => item.imageId === undefined);
        const needToEditImages = Object.keys(imagesToEdit);
        const validate = !products.some((product) => {
          const { name: productName = "" } = product;
          return (
            productName.trim().toLowerCase() === name.value.trim().toLowerCase()
          );
        });

        if (isCreate && !validate) {
          return confirmationModal(
            `Error: A product with the name ${name} already exists; please enter a unique product name.`,
            true,
            5000
          );
        } else {
          fetch(`${apiUrl}products${isCreate ? "" : `/${productId}`}`, {
            method: isCreate ? "POST" : "PUT",
            headers: {
              "Content-Type": "application/json",
              "auth-token": token,
            },
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.success) {
                // Checks to see if there are any images to delete
                if (imagesToDelete.length > 0) {
                  deleteImages();
                }

                // Checks to see if any images do not have a imageId, which means it needs to be uploaded
                if (newImages.length > 0) {
                  uploadImages(newImages, response.product.productId);
                }

                // Checks if any images have been marked for updates
                if (needToEditImages.length > 0) {
                  editImages(needToEditImages);
                }

                dispatchGlobal(
                  updateProducts({
                    body: response.product,
                    productId: response.product?.productId,
                  })
                );

                onSuccess();

                confirmationModal(
                  isCreate
                    ? "Product Successfully Created"
                    : "Product Successfully Updated"
                );
              } else confirmationModal(`Error: ${response.error}`, true, 5000);
            })
            .catch((err) => console.log(err));
        }
      });
  }

  function onRemove(element) {
    const { propertiesMap } = state;
    const newPropertiesMap = { ...propertiesMap };
    const elementTitle = element.title || "";
    delete newPropertiesMap[elementTitle];
  }

  function onChange(event) {
    setState((prevState) => ({
      ...prevState,
      [event.target.id]: {
        error: false,
        id: event.target.id,
        value: event.target.value,
      },
    }));
  }

  function validateFields() {
    let isValidated = true;
    const fieldsToValidate = [name, tag, productType];

    fieldsToValidate.forEach((element) => {
      const { id, value } = element;
      if (value?.length === 0) {
        isValidated = false;
        setState((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true },
          };
        });
      }
    });

    // We verify all images in have a name associated with them
    if (images.filter((element) => element.name.length === 0).length > 0) {
      isValidated = false;
      setCheckImages(true);
    }

    if (isValidated) {
      handleSubmit();
    }
  }

  return (
    (
      <>
        <Grid container sx={classes.root} spacing={2}>
          {/* Product Information */}
          <ProductInformation
            addProductLevelDataElement={addProductLevelDataElement}
            customDataFields={customDataFields}
            description={description}
            name={name}
            onChange={onChange}
            onRemove={onRemove}
            productCategoryOptions={productCategoryOptions}
            productType={productType}
            readOnly={readOnly}
            setAddProductLevelDataElement={setAddProductLevelDataElement}
            setState={setState}
            tag={tag}
          />
          {/* Add Product Data Element Button */}
          {readOnly ? null : (
            <Grid size={6}>
              <Tooltip
                arrow
                placement="right"
                title="Product Level Data Elements are categories of data that are specific to the product line. These stay consistent with the product across production batches and do not change. In general, these are aspects like color, size, and weight. Add them here for use on a Connect Page with LocatorX’s Product Certificate Authority, where you can provide customers with additional information that they can use to identify their items when scanned in LX Productscan."
              >
                <div>
                  <MaterialUiButton
                    color="submit"
                    cypressId="products-product-mdl-btn-add-plde"
                    disabled={addProductLevelDataElement}
                    fullWidth={true}
                    label="Add Product Level Data Elements"
                    onClick={() => {
                      setAddProductLevelDataElement(true);
                    }}
                  />
                </div>
              </Tooltip>
            </Grid>
          )}
          {/* Images */}
          <ImagesDisplayNSave
            acceptedFileTypes={"image/jpeg, image/png, image/gif"}
            apiUrl={apiUrl}
            checkImages={checkImages}
            images={images}
            imagesToEdit={imagesToEdit}
            productId={productId}
            readOnly={readOnly}
            setState={setState}
            token={token}
          />
          {/* Item Level Data Element Select */}
          {addItemLevelDataElement ? (
            <Grid size={12}>
              <ItemLevelDataElementSelect
                itemLevelDataElements={itemLevelDataElements}
                readOnly={readOnly}
                selectedItemLevelDataElements={selectedItemLevelDataElements}
                setState={setState}
              />
            </Grid>
          ) : null}
          {/* Item Level Element buttons */}
          {readOnly ? null : (
            <Grid sx={classes.buttonGroup} size={6}>
              {addItemLevelDataElement ? (
                <Tooltip
                  arrow
                  placement="right"
                  title="If you would like to add Item Level Data Elements, that you have not currently identified, click this button to be redirected to Global Settings. On saving the updated settings, you will be redirected back to creating this product, with all of your data still saved."
                >
                  <Link
                    sx={classes.link}
                    to={{
                      pathname: "/settings",
                      state: {
                        product: {
                          ...state,
                          description: description.value,
                          name: name.value,
                          productType: productType.value,
                          tag: tag.value,
                          urlBase: urlBase.value,
                        },
                        redirect: true,
                        isCreate,
                      },
                    }}
                  >
                    <MaterialUiButton
                      color="secondary"
                      cypressId="products-product-mdl-btn-go-to-gs"
                      fullWidth={true}
                      label="Go To Global Settings"
                    />
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip
                  arrow
                  placement="right"
                  title="Item Level Data Elements must be present in Global Settings in order to be added to a product. If not currently present, user will have the opprotunity to add, if they have sufficient permissions."
                >
                  <div>
                    <MaterialUiButton
                      color="submit"
                      cypressId="products-product-mdl-btn-chose-ilde"
                      fullWidth={true}
                      label="Choose Item Level Data Elements"
                      onClick={() => {
                        setAddItemLevelDataElement(true);
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
        <Grid sx={classes.buttonContainer} container>
          {/* Cancell Button */}
          {readOnly ? null : (
            <Grid size={5}>
              <MaterialUiButton
                color="cancel"
                cypressId="products-product-mdl-btn-cancel"
                fullWidth={true}
                label="CANCEL"
                onClick={modalClose}
                variant="outlined"
              />
            </Grid>
          )}

          {/* Submit Button */}
          {readOnly ? null : (
            <Grid size={5}>
              <MaterialUiButton
                color="submit"
                cypressId="products-product-mdl-btn-submit"
                fullWidth={true}
                label="Submit"
                onClick={validateFields}
              />
            </Grid>
          )}
        </Grid>
      </>
    )
  );
}
