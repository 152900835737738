import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { cloneDeep, isEqual } from "lodash";
import { thunks } from "../../globalStore/slices/organization/organizationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ActionButtons from "../../components/Buttons/ActionButtons";
import AddIcon from "@mui/icons-material/Add";
import ConfirmationModalContent from "../../components/Modals/ConfirmationModalContent";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import EditOrganization from "./EditOrganization";
import GroupsIcon from '@mui/icons-material/Groups';
import HoverIconButton from "../../components/ReusedComponents/HoverIconButton";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import MaterialUiDataGridPremium from "../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";
import ModalDialog from "../../components/Modals/ModalDialog/ModalDialog";
import Users from "../users/Users";
import { GridToolbar } from "@mui/x-data-grid-pro";

export default function Organizations(props) {
  const {
    apiUrl = "",
    history = {
      listen: () => { },
    },
    location: locationFromProps,
    token = "",
    userAvailableActions = [],
  } = props;
  const childOrganizations = cloneDeep(
    useSelector((state) => state.organization.childOrganizations, isEqual)
  );

  const organizationsMap = Object.values(childOrganizations);

  const [state, setState] = useState({
    confirmationModalShow: false,
    isCreate: false,
    // organizationsMap: Object.values(childOrganizations),
    selectedOrganization: {},
    showOrganizationUsers: false,
    switchModal: [],
  });

  // const { organizationsMap } = state;

  const handleState = (updatedState) => {
    setState((s) => {
      return {
        ...s,
        ...updatedState,
      };
    });
  };

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  const { retrieveChildOrganizations: retrieveChildOrganizationsThunk } =
    thunks.childOrganizations;

  // onSuccess function retrieves child organizations, patch/diff with redux store, and resets location state
  const retrieveOrganizations = () => {
    dispatchGlobal(retrieveChildOrganizationsThunk());
    location.state = {};
  };

  // wrapping init up in useCallback to prevent useEffect loop
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(retrieveChildOrganizationsThunk());
      }
    },
    [retrieveChildOrganizationsThunk, dispatchGlobal]
  );

  const mounted = useRef(false);

  useEffect(() => {
    init(mounted.current);

    mounted.current = true;

    // This cleans up the quickLinks and modal behavior
    return history.listen((location) => {
      setState((s) => {
        return { ...s, ...location.state };
      });
    });
  }, [history, init, locationFromProps]);

  const handleDeleteOrganization = () => {
    const { selectedOrganization = {} } = state;
    const { organizationId = "" } = selectedOrganization;

    fetch(`${apiUrl}organizations/${organizationId}`, {
      method: "DELETE",
      headers: {
        "auth-token": token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          handleConfirmationModal("Organization Successfully Deleted");
          retrieveOrganizations();
        }
      })
      .catch(() => {
        handleConfirmationModal("There was a problem", true);
      });
  };

  const handleConfirmationModal = (confirmationText, error = false) => {
    handleState({
      confirmationModalShow: true,
      confirmationText,
      modalShow: false,
      error,
    });
  };

  const {
    confirmationModalShow,
    confirmationText,
    isCreate,
    modal,
    selectedOrganization,
    showOrganizationUsers,
  } = state;

  let modalClose = () =>
    handleState({
      isCreate: false,
      modalShow: false,
      selectedOrganization: {},
    });

  const tableColumns = [
    {
      field: "name",
      flex: 2,
      headerName: "Organization Name",
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === 'group') {
          return data.value;
        }
        return (
          <div
            className="pointer"
            onClick={() => {
              handleState({
                selectedOrganization: row,
                modalShow: true,
                modal: "Organization Information",
              });
            }}
          >
            {row.name}
          </div>
        );
      },
      valueGetter: (value, row) => row?.name || "",
    },
    {
      field: "city",
      flex: 2,
      headerName: "City",
      renderCell: (data) => {
        const { row } = data;
        const { address = {} } = row;
        if (data.rowNode.type === 'group') {
          return data.value;
        }
        return (
          <div
            className="pointer"
            id="city"
            onClick={() => {
              handleState({
                selectedOrganization: row,
                modalShow: true,
                modal: "Organization Information",
              });
            }}
          >
            {address.city}
          </div>
        );
      },
      valueGetter: (value, row) => row?.address?.city || "",
      groupingValueGetter: (value, row) => { return row?.address?.city || null },
    },
    {
      field: "state",
      headerName: "State",
      renderCell: (data) => {
        const { row } = data;
        const { address = {} } = row;
        if (data.rowNode.type === 'group') {
          return data.value;
        }
        return (
          <div
            className="pointer"
            onClick={() => {
              handleState({
                selectedOrganization: row,
                modalShow: true,
                modal: "Organization Information",
              });
            }}
          >
            {address.state}
          </div>
        );
      },
      valueGetter: (value, row) => row?.address?.state || "",
      groupingValueGetter: (value, row) => { return row?.address?.state || null },
      width: 100,
    },
    {
      disableSortBy: true,
      field: "actions",
      flex: 1.5,
      groupable: false,
      headerName: "Actions",
      sortable: false,
      width: 250,
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === 'group') return null;

        const buttonIcons = [];
        if (userAvailableActions.includes("Edit Organizations")) {
          // Edit Organizations details
          buttonIcons.push({
            icon: <EditIcon />,
            title: "Edit",
            action: () => {
              handleState({
                modal: "Edit Organization",
                modalShow: true,
                selectedOrganization: row,
              });
            },
          });

          // Edit Organizations Users
          buttonIcons.push({
            icon: <GroupsIcon />,
            title: "Modify Users",
            action: () => {
              handleState({
                selectedOrganization: row,
                showOrganizationUsers: true,
              });
            },
          });
        }
        if (userAvailableActions.includes("Delete Organizations")) {
          buttonIcons.push({
            icon: <DeleteIcon />,
            title: "Delete",
            action: () => {
              handleState({
                modal: "Delete Organization",
                modalShow: true,
                selectedOrganization: row,
              });
            },
          });
        }
        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  const switchModal = (modal) => {
    switch (modal) {
      case "Edit Organization":
        return (
          <EditOrganization
            apiUrl={apiUrl}
            hasFormFields={true}
            isCreate={isCreate}
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={retrieveOrganizations}
            organization={selectedOrganization}
            token={token}
          />
        );
      case "Create Organization":
        return (
          <EditOrganization
            hasFormFields={true}
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={retrieveOrganizations}
            token={token}
            apiUrl={apiUrl}
            isCreate={isCreate}
            organization={selectedOrganization}
          />
        );
      case "Organization Information":
        return (
          <EditOrganization
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={retrieveOrganizations}
            token={token}
            apiUrl={apiUrl}
            isCreate={isCreate}
            readOnly={true}
            organization={selectedOrganization}
          />
        );
      case "Delete Organization":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedOrganization.name}? This action cannot be
              undone.`}
            handleSubmit={() => {
              handleDeleteOrganization();
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      default:
        return;
    }
  };

  function toolBarButtons() {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        {userAvailableActions?.includes("Create Organizations") ? (
          <HoverIconButton
            handleClick={() =>
              handleState({
                isCreate: true,
                modal: "Create Organization",
                modalShow: true,
              })
            }
            icon={<AddIcon />}
            iconDirection="right"
            text="Create Organization"
          />
        ) : null}
      </Grid>
    );
  }

  return (
    (<Box ml={1}>
      <Grid
        container
        sx={{
          margin: "0 auto",
          width: "84vw",
        }}
      >
        {showOrganizationUsers ? (
          <Users
            {...props}
            apiUrl={apiUrl}
            handleGoBack={() => handleState({ showOrganizationUsers: false })}
            isMainOrg={false}
            selectedOrganization={selectedOrganization}
            token={token}
          />
        ) : (
          <Grid container>
            <ModalDialog
              handleClose={modalClose}
              open={state.modalShow}
              title={modal}
              content={switchModal(modal)}
            />
            <MaterialConfirmationModal
              content={confirmationText}
              closeModal={() =>
                handleState({
                  confirmationModalShow: false,
                  isCreate: false,
                  selectedOrganization: {},
                })
              }
              modalOpen={confirmationModalShow}
              variant="filled"
            />

            {/* Header */}
            <Grid size={12}>
              <Box mt={2} mb={2} textAlign="left">
                <Typography component="h1" variant="h4">
                  Organizations
                </Typography>
              </Box>
            </Grid>

            {/* Table */}
            <Grid size={12}>
              <MaterialUiDataGridPremium
                checkboxSelection
                columns={tableColumns}
                components={GridToolbar}
                disableRowSelectionOnClick={true}
                enableIsRowSelectable={false}
                exportFileName="Organizations"
                getRowId={(row) => row.organizationId}
                loading={false}
                needsCustomToolbar={true}
                rowCount={organizationsMap.length}
                rows={organizationsMap}
                toolBarButtons={toolBarButtons}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>)
  );
}
