import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { isEmpty } from "lodash";
import { parseUserInfo } from "../utils";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next";
import getColumnType from "../../../../utils/get_column_type";
import modifyColumns from "../../../../utils/modifyColumns";

export default function columns(props) {
  const {
    classifications = {},
    columnOrder = [],
    customs = [],
    facilities = {},
    history = {},
    timeZone = "",
    useTranslation = true,
    zones = {},
  } = props || {};

  const { t = () => { } } = useTranslation ? UseTranslation() : () => { };

  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey) => {
      const { name } = classifications[classKey];
      return {
        field: name,
        headerName: name,
        id: name,
        renderCell: (data) => {
          if (data.rowNode.type === "group") return data.value;
          const { row } = data;
          const { classificationMap = {} } = row;

          const classificationValue = Object.keys(classificationMap).filter(
            (k) => k === name
          )[0];

          return (
            <div className="pointer" onClick={() => onClick(row)}>
              {classificationMap[classificationValue] || t("dnpinput1")}
            </div>
          );
        },
        valueGetter: (value, row) => {
          if (row.classificationMap) {
            const classificationValue = row.classificationMap[classKey] || "";
            return classificationValue;
          }
          return "";
        },
        groupingValueGetter: (value, row) => {
          if (row.classificationMap) {
            const classificationValue = row.classificationMap[classKey] || "";
            return classificationValue;
          }
          return null;
        },
      };
    })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
      const { dataType, name } = item;

      const colType = getColumnType(dataType);
      return {
        field: name,
        headerName: name,
        id: name,
        renderCell: (data) => {
          // Check if the row type is "group" or "pinnedRow", and return formatted date or fallback text accordingly.
          if (["group", "pinnedRow"].includes(data.rowNode.type)) {
            return colType === "date" && data.value
              ? new Date(data.value).toLocaleDateString() || t("dnpinput1")
              : data.value || t("dnpinput1");
          }
          const { row } = data;
          const { customMap = {} } = row;
          const customValue = Object.keys(customMap).filter(
            (k) => k === name
          )[0];
          const renderValue = customMap[customValue];

          return (
            <div className="pointer" onClick={() => onClick(row)}>
              {/* //if colType is date and returnVal is not undefined, return the date */}
              {colType === "date" && renderValue !== undefined
                ? new Date(renderValue).toLocaleDateString()
                : renderValue || t("dnpinput1")}
            </div>
          );
        },
        type: colType,
        valueGetter: (value, row) => {
          if (row.customMap) {
            const customValue = row.customMap[name];
            if (colType === "number") {
              return parseInt(customValue) || null;
            }
            if (colType === "date") {
              return customValue !== undefined ? new Date(customValue) : null;
            }
            return customValue;
          }
          return null;
        },
        groupingValueGetter: (value, row) => {
          if (row.customMap) {
            const customValue = row.customMap[name];
            if (colType === "number") {
              return parseInt(customValue) || null;
            }
            if (colType === "date") {
              return customValue !== undefined ? new Date(customValue) : null;
            }
            return customValue;
          }
          return null;
        },
      };
    })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      groupable: false,
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { row } = data;
        const { timeOfLogLong } = row;

        return (
          <div className="pointer" onClick={() => onClick(row)}>
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (value, row) => {
        const timeOfLogLong = row.timeOfLogLong;
        return timeOfLogLong
          ? new Date(timeOfLogLong)
          : //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      },
      width: 200,
    },
    // tag
    {
      field: "tag",
      headerName: "Asset Tag",
      id: "tag",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { tag } = row;

        return (
          <div className="pointer" onClick={() => onClick(row)}>
            {tag ? tag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => (row.tag ? row.tag : t("dnpinput1")),
      groupingValueGetter: (value, row) => (row.tag ? row.tag : null),
      width: 150,
    },
    // category
    {
      field: "category",
      headerName: "Asset Category",
      id: "category",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { category } = row;

        return (
          <div className={"pointer"} onClick={() => onClick(row)}>
            {category ? category : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) =>
        row.category ? row.category : t("dnpinput1"),
      groupingValueGetter: (value, row) => (row.category ? row.category : null),
    },
    // description
    {
      field: "description",
      headerName: "Description",
      id: "description",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { propertiesMap = {} } = row;

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(row)}
          >
            {propertiesMap?.description || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) =>
        row.propertiesMap?.description || t("dnpinput1"),
      groupingValueGetter: (value, row) =>
        row.propertiesMap?.description || null,
    },
    // lastEvent
    {
      field: "lastEvent",
      headerName: "Last Event",
      id: "lastEvent",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { lastEvent } = row;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(row)}
          >
            {lastEvent ? lastEvent : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) =>
        row.lastEvent ? row.lastEvent : t("dnpinput1"),
      groupingValueGetter: (value, row) =>
        row.lastEvent ? row.lastEvent : null,
    },
    // user
    {
      field: "user",
      headerName: "User",
      id: "user",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const userInfo = parseUserInfo(row);

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(row)}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => parseUserInfo(row),
      groupingValueGetter: (value, row) => parseUserInfo(row) || null,
    },
    // facility
    {
      field: "facility",
      headerName: "Facility Name",
      id: "facility",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { facility, facilityId, latitude, longitude } = row;
        return (
          <div className={"pointer"} onClick={() => onClick(row)}>
            {facility
              ? facility
              : !latitude || !longitude
                ? t("dnpinput1")
                : facilityId &&
                  facilities[facilityId] &&
                  facilities[facilityId].name
                  ? facilities[facilityId].name
                  : "Not Registered"}
          </div>
        );
      },
      valueGetter: (value, row) =>
        !row.latitude || !row.longitude
          ? t("dnpinput1")
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
            ? facilities[row.facilityId].name
            : "Not Registered",
      groupingValueGetter: (value, row) =>
        !row.latitude || !row.longitude
          ? null
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
            ? facilities[row.facilityId].name
            : null,
    },
    // zone
    {
      field: "zone",
      headerName: "Zone",
      id: "zone",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;
        return (
          <div className="pointer" onClick={() => onClick(row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
                ? zoneName
                : zoneId && zones[zoneId]
                  ? zones[zoneId].name
                  : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;

        return pZoneName
          ? pZoneName
          : zoneName
            ? zoneName
            : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;

        return pZoneName
          ? pZoneName
          : zoneName
            ? zoneName
            : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : null;
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerName: "Bin Location",
      id: "binLocation",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              row.zone
                ? binLocation
                : row.binLocation
                  ? row.binLocation
                  : row.propertiesMap && row.propertiesMap.binLocation
                    ? row.propertiesMap.binLocation
                    : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (value, row) => row.zone?.binLocation || "",
      groupingValueGetter: (value, row) => row.zone?.binLocation || null,
    },
    // state
    {
      field: "state",
      headerName: "State",
      id: "state",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { state = t("dnpinput1") } = row;
        return (
          <div className={"pointer"} onClick={() => onClick(row)}>
            {state ? state : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => (row.state ? row.state : t("dnpinput1")),
      groupingValueGetter: (value, row) => {
        return row.state ? row.state : null;
      },
      width: 50,
      // groupingValueGetter: (value, row) =>  value || null,
    },
    // latLong
    {
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div className="pointer" onClick={() => onClick(row)}>
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const latitude = row.latitude || t("dnpinput1");
        const longitude = row.longitude || t("dnpinput1");
        return `${latitude}, ${longitude}`;
      },
      groupingValueGetter: (value, row) => {
        const latitude = row.latitude || t("dnpinput1");
        const longitude = row.longitude || t("dnpinput1");
        return `${latitude}, ${longitude}`;
      },
    },
    // parentId
    {
      field: "parentId",
      headerName: "Parent Asset Tag",
      id: "parentId",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;

        const parent =
          !row.ancestorList || !row.ancestors
            ? ""
            : row.ancestorList.filter(
              (ancestor) => ancestor.assetId === row.ancestors[0]
            )[0];

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick({ assetId: parent?.assetId || null })}
          >
            {parent.tag || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        if (!row.ancestorList || !row.ancestors) {
          return "";
        } else {
          // immediate parent is the first item in ancestors set, the child item's parent node in the hierarchy tree
          const parent = row.ancestorList.filter(
            (ancestor) => ancestor.assetId === row.ancestors[0]
          )[0];
          return parent.tag || "";
        }
      },
      groupingValueGetter: (value, row) => {
        if (!row.ancestorList || !row.ancestors) {
          return "";
        } else {
          // immediate parent is the first item in ancestors set, the child item's parent node in the hierarchy tree
          const parent = row.ancestorList.filter(
            (ancestor) => ancestor.assetId === row.ancestors[0]
          )[0];
          return parent.tag || null;
        }
      },
    },
    // timeCreatedUser
    {
      field: "timeCreatedUser",
      groupable: false,
      headerName: "Create Date",
      id: "timeCreatedUser",
      renderCell: (data) => {
        // if(data.rowNode.type === "group") return data.value;
        const { row } = data;
        return (
          //different, but similar to timeOfLog. This is for time user would like to indicate its creation. timeOf log indicates time of entry into system
          <div className={"pointer"} onClick={() => onClick(row)}>
            {/* { row.timeCreatedLong ? new Date(row.timeCreatedLong).toLocaleDateString() : t("dnpinput1") } */}
            {/* { row.timeCreatedLong ? new Date(row.timeCreatedLong) : t("dnpinput1") } */}
            {row.timeCreatedUserLong
              ? unix_utc_to_local_12_hour_clock(row.timeCreatedUserLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "date",
      valueGetter: (value, row) => {
        const timeCreatedUserLong = row.timeCreatedUserLong;
        return timeCreatedUserLong
          ? new Date(timeCreatedUserLong)
          : //furthest date in the future. We want records without a timeCreatedUser to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      },
    },
    // timeNeeded
    {
      field: "timeNeeded",
      groupable: false,
      headerName: "Need Date",
      id: "timeNeeded",
      renderCell: (data) => {
        // if(data.rowNode.type === "group") return data.value;
        const { row } = data;
        return (
          <div className={"pointer"} onClick={() => onClick(row)}>
            {row.timeNeeded
              ? // ? new Date(row.timeNeeded).toLocaleDateString()
              // ? new Date(row.timeNeededLong)
              unix_utc_to_local_12_hour_clock(row.timeNeededLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "date",
      valueGetter: (value, row) =>
        row.timeNeeded
          ? new Date(row.timeNeededLong)
          : new Date(8640000000000000),
    }
  ]
    .concat(classificationColumns)
    // TODO - Elastic - Uncomment this out once customs are fixed
    .concat(customColumns);

  //iterate over columns and if a column has a type of number, allow aggregation on that column
  historyTableColumns.forEach((column) => {
    if (column.type === "number") {
      column.aggregable = true;
    } else {
      column.aggregable = false;
    }
  });

  const onClick = (row) => {
    const { assetId = "", assetType = "", batchId = "" } = row;
    if (assetId || batchId) {
      const url = batchId
        ? `/batches/${batchId}/history/${assetType}`
        : `/assetSnapshot/${assetId}`;
      history.push(url);
    }
  };

  // Here we need to go through the columnOrder and handle Sorting / Renaming / Visibility settings
  if (columnOrder) {
    return modifyColumns({ columnOrder, historyTableColumns });
  }

  return { historyTableColumns };
}
