import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ArrowDropDownCircleRounded } from "@mui/icons-material";
import { fixedDrawerStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import lxlogowhitegreen from "../../../../assets/lxlogowhitegreen.png";
import MuiSVGIcon from "../../../IconPicker/MuiSVGIcon";

export default function FixedDrawer(props) {
  const { appBarHeight, navLinks, sideNavWidth, stickyNavLinks } = props;
  const classes = fixedDrawerStyles({ sideNavWidth, appBarHeight });
  const history = useHistory();
  const isActive = (path) => {
    return history.location.pathname.includes(path);
  };

  const recentAssets = useSelector((state) => state.recents.assets);
  const recentInvAssets = useSelector((state) => state.recents.inventoryAssets);
  const recentFacilities = useSelector((state) => state.recents.facilities);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openRecentsDropdown, setOpenRecentsDropdown] = useState(false);
  const [pagesRecents, setPagesRecents] = useState([]);
  const [currPageName, setCurrPageName] = useState("");

  function handleDropdownClose() {
    setAnchorEl(null);
    setOpenRecentsDropdown(false);
    setPagesRecents([]);
  }

  function handleRecentItemClick(itemInfo) {
    //itemInfo is assetId for assets and inventory assets, and facilityData object for facilities
    //if is asset or inventory, then redirect to the assetSnapshot page with the itemInfo
    if (currPageName === "assets" || currPageName === "inventory") {
      history.push(`/assetSnapshot/${itemInfo}`);
    }
    //if is facility, then redirect to the facility page with the selected facility's data
    else if (currPageName === "facilities") {
      history.push(`/facilities/overview`, { selectedFacility: itemInfo });
    }
  }

  return (
    <Drawer variant="permanent" sx={classes.drawer}>
      <List>
        <ListItem
          className="pointer"
          key="logo-nav-item"
          onClick={() => {
            history.push("/");
          }}
          sx={classes.menuIcon}
          tabindex="0"
        >
          <img
            alt="locatorx company logo"
            src={lxlogowhitegreen}
            height="100%"
          />
        </ListItem>
        {/* <Divider /> */}
        {navLinks.map((link, idx) => {
          return (
            //render list item based on whether or not its asset, inv, or facility.
            //those have a button with arrow for recent items
            link.name === "assets" || link.name === "inventory" || link.name === "facilities" ?
              (
                <ListItem
                  key={`${link.name} - ${idx}`}
                  sx={{
                    ...classes.listItem,
                    backgroundColor: (theme) => {
                      if (isActive(link.name)) {
                        return theme?.palette?.success?.main;
                      }
                    },
                    width: "102px",
                  }}
                  tabindex="0"
                >

                  {/* Icon and Text */}
                  <Box
                    onClick={() => {
                      if (link.hyperlink) {
                        window.open(link.hyperlink, "_blank", "noopener,noreferrer");
                      } else {
                        history.push(`${"/"}${link.name}`);
                      }
                    }}
                    alignSelf="flex-end"
                    display="flex" flexDirection="column" alignItems="center"
                    paddingLeft="22px"
                  >
                    <MuiSVGIcon
                      icon={link.icon}
                      key={`${link.name} - ${idx} icon`}
                      sx={classes.listItemIcon}
                    />
                    <ListItemText
                      primary={link.label}
                      key={`${link.name} - ${idx} text`}
                      sx={classes.listItemText}
                    />
                  </Box>

                  {/* Dropdown Button */}
                  <IconButton
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                      setOpenRecentsDropdown(true);
                      if (link.name === "assets") {
                        setPagesRecents(recentAssets);
                        setCurrPageName("assets");
                      }
                      else if (link.name === "inventory") {
                        setPagesRecents(recentInvAssets);
                        setCurrPageName("inventory");
                      }
                      else if (link.name === "facilities") {
                        setPagesRecents(recentFacilities);
                        setCurrPageName("facilities");
                      }
                    }}
                    size="small">
                    <ArrowDropDownCircleRounded />
                  </IconButton>

                  {/* Dropdown Menu */}
                  <Menu
                    anchorEl={anchorEl}
                    onClose={handleDropdownClose}
                    open={openRecentsDropdown}
                  >
                    <Typography sx={{ px: 2, py: 1, fontWeight: "bold" }}>Recently Viewed</Typography>
                    {pagesRecents.map((recent, index) => {
                      return (
                        <MenuItem key={index}
                          onClick={() => handleRecentItemClick(recent.assetId || recent.facilityData)}
                        >
                          {recent.assetTag || recent.facilityName}
                        </MenuItem>
                      )
                    }
                    )}
                  </Menu>
                </ListItem>
              ) :
              (
                <ListItem
                  key={`${link.name} - ${idx}`}
                  onClick={() => {
                    if (link.hyperlink) {
                      window.open(link.hyperlink, "_blank", "noopener,noreferrer");
                    } else {
                      history.push(`${"/"}${link.name}`);
                    }
                  }}
                  sx={{
                    ...classes.listItem,
                    // I spent too much time trying to figure this out in the styles.js
                    backgroundColor: (theme) => {
                      if (isActive(link.name)) {
                        return theme?.palette?.success?.main;
                      }
                    },
                  }}
                  tabindex="0"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <MuiSVGIcon
                      icon={link.icon}
                      key={`${link.name} - ${idx} icon`}
                      sx={classes.listItemIcon}
                    />
                    <ListItemText
                      primary={link.label}
                      key={`${link.name} - ${idx} text`}
                      sx={classes.listItemText}
                    />
                  </Box>
                </ListItem>)
          )
        })}

        <Box sx={classes.bottomPush} />

        {/* For information and support menu items on the bottom of the nav */}
        {stickyNavLinks.map((link, idx) => {
          return (
            <ListItem
              key={`${link.name} - ${idx}`}
              onClick={() => {
                if (link.hyperlink) {
                  window.open(link.hyperlink, "_blank", "noopener,noreferrer");
                } else {
                  history.push(`${"/"}${link.name}`);
                }
              }}
              sx={{
                ...classes.listItem,
                // I spent too much time trying to figure this out in the styles.js
                backgroundColor: (theme) => {
                  if (isActive(link.name)) {
                    return theme?.palette?.success?.main;
                  }
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
              >
                <MuiSVGIcon
                  icon={link.icon}
                  key={`${link.name} - ${idx} icon`}
                  sx={classes.listItemIcon}
                />
                <ListItemText
                  primary={link.label}
                  key={`${link.name} - ${idx} text`}
                  sx={classes.listItemText}
                />
              </Box>
            </ListItem>
          );
        })}

      </List>
    </Drawer>
  );
}
