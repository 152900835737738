import Grid from "@mui/material/Grid2";
import Dashboard from "../dashboard/dashboard";
import QueryBuilder from "../../components/QueryBuilder/QueryBuilder";

export default function SplashPage(props) {
  return (
    (<Grid container>
      <Grid size={12}>
        <QueryBuilder
          {...props}
          showAssetMode={true}
          showResultLimit={true}
          showSelectTable={true}
        />
        {/* <Dashboard {...props} /> */}
      </Grid>
    </Grid>)
  );
}