import { isEmpty } from "lodash";
import { formatLatLon } from "../../utils/lat_lon_utils";
import {
  utc_to_local_12_hour_clock,
  utc_to_local_24_hour_clock,
} from "../../utils/date_time_utils";
import { unix_utc_to_local_12_hour_clock } from "../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next";
import getColumnType from "../../utils/get_column_type";
import modifyColumns from "../../utils/modifyColumns";

export default function columns(props) {
  const {
    classes = {},
    classifications = [],
    columnOrder = [],
    customs = [],
    devices = {},
    facilities = {},
    isInventorySnapshot = false,
    onRowClick = () => { },
    timeZone = '',
    useTranslation = true,
    zones = {},
  } = props;

  const { t = () => { } } = useTranslation ? UseTranslation() : () => { };

  const parseUserInfo = (row, firstLast = false) => {
    if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
      return `${row.firstName || ""}${row.firstName && row.lastName ? " " : ""
        }${row.lastName || ""}`;
    }
    if (row.appUserId && (row.firstName || row.lastName)) {
      return `${row.lastName || ""}${row.firstName && row.lastName ? ", " : ""
        }${row.firstName || ""}`;
    } else {
      return ``;
    }
  };

  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey) => {
      const { name } = classifications[classKey];
      return {
        field: name,
        headerName: name,
        renderCell: (data) => {
          const { row, tabIndex } = data;
          const { classificationMap = {} } = row;

          const classificationValue = Object.keys(classificationMap).filter(
            (k) => k === name
          )[0];

          return (
            <div
              className="pointer"
              key={tabIndex}
              onClick={() => onRowClick(row)}
            >
              {classificationMap[classificationValue] || t("dnpinput1")}
            </div>
          );
        },
        valueGetter: (value, row) => {
          const { classificationMap = {} } = row;

          const classificationValue = Object.keys(classificationMap).filter(
            (k) => k === name
          )[0];

          return classificationMap[classificationValue] || null;
        },
      };
    })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
      const { customId = "", dataType, name = "" } = item;

      const colType = getColumnType(dataType);

      return {
        field: name,
        headerName: name,
        id: "name",
        renderCell: (data) => {
          // Check if the row type is "group" or "pinnedRow", and return formatted date or fallback text accordingly.
          if (["group", "pinnedRow"].includes(data.rowNode.type)) {
            return colType === "date" && data.value
              ? new Date(data.value).toLocaleDateString() || t("dnpinput1")
              : data.value || t("dnpinput1");
          }

          const { row, tabIndex } = data;
          const { customMap = {} } = row;

          const customValue = Object.keys(customMap).filter(
            (k) => k === name
          )[0];

          const renderValue = customMap[customValue];

          return (
            <div
              className="pointer"
              key={`${tabIndex}-${customId}}`}
              onClick={() => onRowClick(row)}
            >
              {/* //if colType is date and returnVal is not undefined, return the date */}
              {colType === "date" && renderValue !== undefined
                ? new Date(renderValue).toLocaleDateString()
                : renderValue || t("dnpinput1")}
            </div>
          );
        },
        valueGetter: (value, row) => {
          const { customMap = {} } = row;

          const customValue = Object.keys(customMap).filter(
            (k) => k === name
          )[0];
          const returnVal = customMap[customValue];
          if (colType === "number") {
            return parseInt(returnVal) || null;
          }

          if (colType === "date") {
            return returnVal !== undefined ? new Date(returnVal) : null;
          }
          return returnVal || null;
        },
        groupingValueGetter: (value, row) => {
          const { customMap = {} } = row;

          const customValue = Object.keys(customMap).filter(
            (k) => k === name
          )[0];
          const returnVal = customMap[customValue];
          if (colType === "number") {
            return parseInt(returnVal) || null;
          }
          if (colType === "date") {
            return returnVal !== undefined ? new Date(returnVal) : null;
          }
          return returnVal || null;
        },
      };
    })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      groupable: false,
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { row } = data;
        const { id, timeOfLogLong } = row;
        return (
          <div
            className={classes.columnHeader}
            onClick={() => onRowClick(row)}
            key={id}
          >
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (value, row) => {
        const timeOfLogLong = row.timeOfLogLong;
        return timeOfLogLong
          ? new Date(timeOfLogLong)
          : //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      },
      width: 200,
    },
    // assetTag
    {
      field: "assetTag",
      headerName: `${isInventorySnapshot ? "Inventory" : "Asset"} Tag`,
      id: "assetTag",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { assetTag } = row;
        return (
          <div
            onClick={() => onRowClick(row)}
            className={classes.columnHeader}
            key={row.assetId}
          >
            {assetTag ? assetTag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { assetTag } = row;
        return assetTag ? assetTag : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { assetTag } = row;
        return assetTag ? assetTag : null;
      },
      width: 150,
    },
    // assemblyId
    {
      field: "assemblyId",
      headerName: "Ticket Id",
      id: "assemblyId",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { assemblyName } = row;
        return (
          <div
            onClick={() => onRowClick(row)}
            className={classes.columnHeader}
            key={row.assetId}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {assemblyName ? assemblyName : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { assemblyName } = row;
        return assemblyName ? assemblyName : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { assemblyName } = row;
        return assemblyName ? assemblyName : null;
      },
    },
    // event
    {
      field: "event",
      headerName: "Event",
      id: "event",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { event } = row;
        return (
          <div
            onClick={() => onRowClick(row)}
            className={classes.columnHeader}
            key={row.assetId}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {event ? event : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { event } = row;
        return event ? event : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { event } = row;
        return event ? event : null;
      },
    },
    // user
    {
      field: "user",
      headerName: "User",
      id: "user",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const userInfo = parseUserInfo(row);
        return (
          <div
            key={row.assetId}
            onClick={() => onRowClick(row)}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const userInfo = parseUserInfo(row);
        return userInfo || t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const userInfo = parseUserInfo(row);
        return userInfo || null;
      },
    },
    // facility
    {
      field: "facility",
      headerName: "Facility Name",
      id: "facility",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { facility, facilityId, latitude, longitude } = row;
        return (
          <div
            onClick={() => onRowClick(row)}
            className={classes.columnHeader}
            key={row.assetId}
          >
            {facility
              ? facility
              : !latitude && !longitude
                ? t("dnpinput1")
                : facilityId &&
                  facilities[facilityId] &&
                  facilities[facilityId].name
                  ? facilities[facilityId].name
                  : "Not Registered"}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { facility, facilityId, latitude, longitude } = row;
        return facility
          ? facility
          : !latitude && !longitude
            ? t("dnpinput1")
            : facilityId && facilities[facilityId] && facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered";
      },
      groupingValueGetter: (value, row) => {
        const { facility, facilityId, latitude, longitude } = row;
        return facility
          ? facility
          : !latitude && !longitude
            ? null
            : facilityId && facilities[facilityId] && facilities[facilityId].name
              ? facilities[facilityId].name
              : null;
      },
    },
    // zone
    {
      field: "zone",
      headerName: "Zone",
      id: "zone",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;
        return (
          <div key={row.assetId} onClick={() => onRowClick(row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
                ? zoneName
                : zoneId && zones[zoneId]
                  ? zones[zoneId].name
                  : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;
        return pZoneName
          ? pZoneName
          : zoneName
            ? zoneName
            : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;
        return pZoneName
          ? pZoneName
          : zoneName
            ? zoneName
            : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : null;
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerName: "Bin Location",
      id: "binLocation",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return (
          <div key={row.assetId} onClick={() => onRowClick(row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              row.zone
                ? binLocation
                : row.binLocation
                  ? row.binLocation
                  : row.propertiesMap && row.propertiesMap.binLocation
                    ? row.propertiesMap.binLocation
                    : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return row.zone
          ? binLocation
          : row.binLocation
            ? row.binLocation
            : row.propertiesMap && row.propertiesMap.binLocation
              ? row.propertiesMap.binLocation
              : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return row.zone
          ? binLocation
          : row.binLocation
            ? row.binLocation
            : row.propertiesMap && row.propertiesMap.binLocation
              ? row.propertiesMap.binLocation
              : null;
      },
    },
    // state
    {
      // not rendered on inv snapshot table
      field: "state",
      headerName: "State",
      id: "state",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { state = t("dnpinput1") } = row;
        return (
          <div
            onClick={() => onRowClick(row)}
            className={classes.columnHeader}
            key={row.assetId}
          >
            {state ? state : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { state = t("dnpinput1") } = row;
        return state ? state : t("dnpinput1");
      },
      groupingValueGetter: (value, row) => {
        const { state } = row;
        return state ? state : null;
      },
      width: 50,
    },
    // latLong
    {
      // not rendered on inv snapshot table
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        if (data.rowNode.type === "group") return data.value;
        const { row } = data;
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div
            onClick={() => onRowClick(row)}
            className={classes.columnHeader}
            key={row.assetId}
          >
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (value, row) => {
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return formattedLatLong;
      },
      groupingValueGetter: (value, row) => {
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return formattedLatLong || null;
      },
    },
    // quantityCurrent
    {
      field: "quantityCurrent",
      headerName: "Qty",
      id: "quantityCurrent",
      renderCell: (data) => {
        if (data.rowNode.type === "group" || data.rowNode.type === "pinnedRow")
          return data.value;
        const { row } = data;
        return (
          <div key={row.assetId} onClick={() => onRowClick(row)}>
            {row.quantity?.quantityCurrent || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (value, row) => {
        return row.quantity?.quantityCurrent || null;
      },
      groupingValueGetter: (value, row) => {
        return row.quantity?.quantityCurrent || null;
      },
      width: 50,
    },
  ]
    // check to make sure "state" and "lat,long" columns dont show up on inventory status page
    .filter((col) => {
      if (isInventorySnapshot && col.id === "state") {
        return false;
      }

      // since this file is used to show table for assets and inventory assets' snapshot, filter out assembly id column when on asset snapshot table
      else if (!isInventorySnapshot && col.id === "assemblyName") {
        return false;
      }
      // CBE 12-21-2023 Bringing back latlong for inventory snapshot
      //  else if (isInventorySnapshot && col.id === "latLong") {
      //   return false;
      // }
      else {
        return true;
      }
    })
    .concat(classificationColumns)
    .filter((col) => {
      // get rid of event specific comments
      if (col.field.includes("Comments") && col.field !== "Comments") {
        return false;
      } else {
        return true;
      }
    })
    // TODO - Elastic Wait for this to get refactored on AC4
    .concat(customColumns);

  //iterate over columns and if a column has a type of number, allow aggregation on that column
  historyTableColumns.forEach((column) => {
    if (column.type === "number") {
      column.aggregable = true;
    } else {
      column.aggregable = false;
    }
  });

  // Woof - TODO - These columns were used when we displayed telemetry data in the asset snapshot page.
  // No longer doing this at the moment. Saving for when we DO render the
  const deviceTableColumns = [
    {
      headerName: "Date/Time",
      accessor: (row) =>
        row.timeOfReport ||
        utc_to_local_24_hour_clock(row.timeOfReport, timeZone) ||
        t("dnpinput1"),
      id: "timeOfReport",
      Cell: (data) => {
        const { timeOfReport } = data.row.original;

        return (
          <div
            onClick={() => onRowClick(data.row.original)}
            className={classes.columnHeader}
          >
            {utc_to_local_12_hour_clock(timeOfReport, timeZone)
              ? utc_to_local_12_hour_clock(timeOfReport, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
    },
    {
      headerName: "Tracker Identifier",
      accessor: "deviceIdentifier",
      id: "deviceIdentifier",
      Cell: (data) => {
        const { assetId } = data.row.original;
        const { tag } = devices[assetId];
        return (
          <div onClick={() => onRowClick(data.row.original)}>
            {tag || t("dnpinput1")}
          </div>
        );
      },
    },
    {
      headerName: "Type",
      accessor: "type",
      id: "type",
      Cell: (data) => {
        const { reportType } = data.row.original;
        return (
          <div onClick={() => onRowClick(data.row.original)}>
            {reportType || t("dnpinput1")}
          </div>
        );
      },
    },
    {
      headerName: "Node",
      accessor: "node",
      id: "node",
      Cell: (data) => {
        const { node } = data.row.original;
        return (
          <div onClick={() => onRowClick(data.row.original)}>
            {node || t("dnpinput1")}
          </div>
        );
      },
    },
    {
      headerName: "Radio ID",
      accessor: "radioId",
      id: "radioId",
      Cell: (data) => {
        const { radio } = data.row.original;
        return (
          <div onClick={() => onRowClick(data.row.original)}>
            {radio || t("dnpinput1")}
          </div>
        );
      },
    },
  ];

  // w00f - TODO - Previously this onClick was to display a modal displaying content about the assetHistory.
  // Previously, this function was turned off. Been told to remove the logic but preserve the code
  // until we get a thumbs up that people do not want a modal. Moved logic over to props.onRowClick
  // const onClick = (rowInfo) => {
  //   const {
  //     classificationMap = {},
  //     customMap = {},
  //     event,
  //     propertiesMap = {},
  //     timeOfLog,
  //   } = rowInfo;
  //   if (propertiesMap.note || propertiesMap.formData) {
  //     const content = (
  //       <div style={{ width: "100%", float: "left" }}>
  //         <button onClick={() => console.log({ propertiesMap, customMap, classificationMap, rowInfo })}>hi</button>
  //         <div style={{ margin: ".5rem 0" }}>

  //           {/* Render note if present */}
  //           {propertiesMap.note ? (
  //             <>
  //               <span style={{ fontWeight: "bold" }}>Notes: </span>
  //               <p style={{ whiteSpace: "pre-wrap" }}>{propertiesMap.note}</p>
  //             </>
  //           ) : null}

  //           {/* Render Customs */}
  //           {Object.keys(customMap).map((custom, index) => {
  //             return (
  //               <div key={`${custom}-${index}`}>
  //                 <span style={{ fontWeight: "bold", marginRight: ".5rem" }}>
  //                   {custom}{" "}
  //                 </span>
  //                 <p>{customMap[custom]}</p>
  //               </div>
  //             );
  //           })}

  //           {/* Render Classifications */}
  //           {Object.keys(classificationMap).map((classification, index) => {
  //             return (
  //               <div key={`${classification}-${index}`}>
  //                 <span style={{ fontWeight: "bold", marginRight: ".5rem" }}>
  //                   {classification}{" "}
  //                 </span>
  //                 <p>{classificationMap[classification]}</p>
  //               </div>
  //             );
  //           })}

  //           {/* The logic states to return true if propertiesMap.formData is present
  //           yet no logic renders the form data */}

  //         </div>
  //       </div>
  //     );

  //     // eslint-disable-next-line no-unreachable
  //     setInfoModal({
  //       modalShow: true,
  //       title: `${event ? event : ``} ${utc_to_local_12_hour_clock(
  //         timeOfLog,
  //         timeZone
  //       )}`,
  //       content: content ? content : "",
  //     });
  //   }
  // };

  // We need to get some live data with the deviceTable stuff.
  // I believe we plan on removing it TODO
  if (columnOrder) {
    return modifyColumns({ columnOrder, historyTableColumns });
  }

  return { historyTableColumns, deviceTableColumns };
}
