import { useState } from "react";
import _ from "lodash";
import { FormControl, TextField } from "@mui/material/";
import { searchAssets } from "../../../utils/API/Assets/Assets";
import { useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";

export default function AssetSelector(props) {
  const {
    apiUrl,
    assetMode = "AllAssets",
    onError = () => { },
    organizationId,
    size = "small",
    token,
  } = props;
  const [options, setOptions] = useState([]);
  const history = useHistory();

  const debounce_try = _.debounce((inputValue) => {
    searchAssets(
      { apiUrl, token, organizationId },
      { filters: { assetMode, tag: inputValue, limit: 300 } }
    ).then((results) => {
      if (results.error) {
        onError(results.error);
      }
      const options =
        results.assets.map((asset) => {
          return {
            label: asset.tag,
            value: asset.assetId,
          };
        }) || [];

      setOptions(options);
    });
  }, 1000);

  function onChange(event) {
    const { value = "" } = event || {};

    if (event) {
      history.push(`/assetSnapshot/${value}`);
    }
  }

  return (
    <FormControl sx={{ width: "100%" }} variant="filled">
      {/* https://mui.com/material-ui/react-autocomplete/#search-as-you-type */}
      <Autocomplete
        autoComplete
        disableCloseOnSelect
        filterOptions={(x) => x}
        filterSelectedOptions
        fullWidth
        getOptionLabel={(option) => option?.label}
        id="inventory-ea-txtselect-binlocation"
        includeInputInList
        multiple={false}
        noOptionsText="Start typing to search assets"
        onChange={(event, value) => onChange(value)}
        onClose={() => setOptions([])}
        onInputChange={(event, newInputValue) => {
          if (
            newInputValue !== "undefined" &&
            newInputValue?.trim().length !== 0
          ) {
            debounce_try(newInputValue);
          }
        }}
        options={options}
        renderInput={(params) => (
          <TextField fullWidth label="Search for an asset..." {...params} />
        )}
        size={size}
        sx={{
          backgroundColor: "white",
        }}
      />
    </FormControl>
  );
}
