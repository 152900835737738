import { useEffect, useState } from "react";
import { getClassificationsByType } from "../../utils/getClassificationsByType";
import { getCustomsByType } from "../../utils/getCustomsByType";
import { GridPagination } from "@mui/x-data-grid-pro";
import { isEqual } from "lodash";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import buildAssetColumns from "../../pages/assets/assetStatus/Table/columns";
import buildAssetHistoryColumns from "../../pages/assets/assetHistory/Table/columns"
import Grid from "@mui/material/Grid2";
import MaterialUiDataGridPremium from "../Tables/DataGridPremium/MaterialUiDataGridPremium";
import moment from "moment";
import QueryBuilderFilters from "./QueryBuilderFilters";

export default function QueryBuilder(props) {
  const {
    // The parent component will need to pass what columns should be rendered here.
    // If columns are supplied we will use those, otherwise we use default columns below.
    defaultFilters,
    showAssetMode = false,
    showResultLimit = false,
    showSelectTable = false,
    timeZone,
    token,
    userId,
  } = props;
  const [state, setState] = useState({
    filters: {
      assetCategories: [],
      assetMode: 'Assets',
      binLocations: [],
      classifications: [],
      customs: [],
      endDate: new Date(),
      events: [],
      limit: 10000,
      selectedTable: 'Assets',
      // Start date willd default to todays date
      startDate: new Date(),
      tag: "",
      users: [],
    },
    assetHistories: [],
    realCount: false,
  });
  const { realCount, filters = {} } = state;
  const { assetMode, selectedTable } = filters;
  const history = useHistory();
  const {
    zones,
    facilities,
  } = useSelector((state) => state.organization, isEqual);
  const isAssetsTable = selectedTable === "Assets";
  const currentTime = moment().tz(timeZone).format("YYYY-MM-DD");
  const exportFileName = isAssetsTable ? `${assetMode === "Inventory" ? "Inv" : "Asset"}-Analysis_${currentTime}` : `Event-Analysis_${currentTime}`;
  const classificationsList = getClassificationsByType({ assetMode: assetMode === "Inventory" ? "Inventory" : "Asset", isObject: false });
  const customsList = getCustomsByType({ assetMode: assetMode === "Inventory" ? "Inventory" : "Asset" });

  // default table columns for when the assets table is selected
  const { historyTableColumns: assetTableColumns } = buildAssetColumns({
    ...props,
    classifications: classificationsList,
    customs: customsList,
    facilities,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  // default table columns for when the asset histories table is selected
  const { historyTableColumns: assetHistoryTableColumns } = buildAssetHistoryColumns({
    ...props,
    classifications: classificationsList,
    customs: customsList,
    facilities,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
    useTranslation: false
  });

  // Default columns is no columns are provided. This also accomodates for when a user starts to switch
  // back and forth between the /assets and /assetHistories endpoints. As the columns will change.
  const columns = props?.columns ? props?.columns : isAssetsTable ? assetTableColumns : assetHistoryTableColumns;

  function CustomPagination() {
    return (<Typography component="div"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2px"
      }}
    >
      {realCount && realCount > 10000 ? <Typography component="p" sx={{ fontSize: "0.875rem" }} >Real Count: {realCount}</Typography> : <p>{" "}</p>}
      <GridPagination />
    </Typography>
    )
  }

  useEffect(() => {
    // We need to see if default state is provided to us, if so we need to throw it into
    // component state.
    if (defaultFilters) {
      setState(prevState => ({ ...prevState, filters: { ...prevState.filters, ...defaultFilters } }));
    }
  }, [defaultFilters, setState]);

  return (
    (<Grid
      container
      className="inventory-analyze"
      style={{
        marginTop: "1rem",
        width: "84vw",
      }}
    >
      {/* Filters  */}
      <Grid size={12}>
        <QueryBuilderFilters
          {...props}
          classifications={classificationsList}
          customs={customsList}
          isAssetsTable={isAssetsTable}
          setState={setState}
          showAssetMode={showAssetMode}
          showResultLimit={showResultLimit}
          showSelectTable={showSelectTable}
          state={state}
        />
      </Grid>
      {/* Table */}
      <Grid sx={{ margin: "1rem 0" }} size={12}>
        <MaterialUiDataGridPremium
          autoHeight={false}
          checkboxSelection={false}
          // columns={isAssetsTable ? inventoryTableColumns : historyTableColumns}
          columns={columns}
          customComponents={{
            Footer: CustomPagination,
          }}
          delayedLoad={false}
          disableAggregation={false}
          disableCsvExportOption={false}
          enableIsRowSelectable={false}
          exportFileName={exportFileName}
          getRowId={(row) => row.assetHistoryId ? row.assetHistoryId : row.assetId}
          loading={false}
          rows={state?.assetHistories}
        />
      </Grid>
    </Grid>)
  );
}
